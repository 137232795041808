<template>
  <div class="mt-2" style="padding-bottom: 70px;">
    <template v-if="!isPro">
      <div class="contract_status_info" v-if="showContractAcceptedForCheck">
        Договор отправлен на проверку. <br />
        Об изменении статуса договора мы сообщим вам в интерфейсе Агентского
        кабинета.
      </div>
      <div class="contract_status_info" v-if="showContractIsSigned">
        ✔︎ Договор заключен.
        <a
          style="font-weight: bold;"
          target="_blank"
          href="#"
          @click.prevent="
            saveAxios({
              url: downloadContractURL,
              label: 'МСБ-Лизинг Договор.pdf'
            })
          "
          >Скачать</a
        >
      </div>
      <div class="contract_status_info" v-if="showContractDecline">
        ✗︎ Договор отклонен. Свяжитесь с вашим менеджером.
      </div>
      <div class="contract_status_info" v-if="showContractNeedsFix">
        ✎ Договор отклонен при проверке. Для продолжения работы необходимо
        внести исправления. Дополните или свяжитесь с вашим менеджером.
      </div>
    </template>
    <v-row>
      <v-col>
        <v-radio-group label="Выберите ваш статус" v-model="agentType">
          <v-radio
            style="text-transform:uppercase;"
            v-for="(item, i) in agentTypes"
            :key="i"
            :disabled="disabledForm"
            :label="item.text"
            :value="item.type"
            @click="changeAgentType(item)"
          ></v-radio>
        </v-radio-group>

        <v-radio-group
          :rules="rules.non_empty"
          label="Выберите систему налогообложения"
          v-model="profile.nalog_type"
          v-if="agentType !== 1"
        >
          <v-radio
            :disabled="disabledForm"
            v-for="(item, i) in nalogTypes"
            :key="i"
            :value="item.type"
          >
            <template v-slot:label>
              <div style="font-size: 90%;">{{ item.name }}</div>
            </template>
          </v-radio>
        </v-radio-group>
      </v-col>
      <!--      для физиков прячем выбор налогообложения-->
      <v-col> </v-col>
    </v-row>

    <v-form
      v-if="agentType"
      :disabled="disabledForm"
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <template v-if="isIP || isCompany">
        <v-row>
          <v-col cols="12" md="6" sm="6">
            <v-autocomplete
              v-model="companyData"
              :item-text="companyText"
              :items="companyItems"
              :loading="loadingCompany"
              :search-input.sync="searchCompany"
              flat
              hide-no-data
              item-value="false"
              label="Укажите ИНН или название организации"
              no-filter
              return-object
            ></v-autocomplete>
          </v-col>
          <!--            :rules="rules.companyData"-->
          <v-col cols="12" md="6" sm="6">
            <v-row>
              <v-col class="text-caption py-0" cols="auto">ИНН:</v-col>
              <v-spacer style="border-bottom: 1px dotted"></v-spacer>
              <v-col class="text-caption py-0" cols="auto">{{
                profile.inn
              }}</v-col>
            </v-row>
            <v-row>
              <v-col class="text-caption py-0" cols="auto"
                >Дата регистрации:
              </v-col>
              <v-spacer style="border-bottom: 1px dotted"></v-spacer>
              <v-col class="text-caption py-0" cols="auto">{{
                profile_registration_date()
              }}</v-col>
            </v-row>
            <v-row align="end">
              <v-col
                class="text-caption py-0"
                cols="auto"
                style="xmax-width: 50%"
                >Юридический адрес
              </v-col>
              <v-spacer style="border-bottom: 1px dotted"></v-spacer>
              <v-col class="text-caption py-0" cols="auto">
                {{ profile.address_legal }}
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <div
          v-if="isCompany"
          class="h6"
          style="margin-bottom: 20px;margin-top: 30px;"
        >
          Загрузите скан-копии документов
        </div>
        <div v-if="isOgrnLoad">
          <v-row>
            <v-col cols="12">
              <template v-if="isIP">
                Загруженный ОГРНИП:
              </template>
              <template v-else>
                Загруженный ОГРН:
              </template>
            </v-col>
            <v-col cols="10">
              <a
                target="_blank"
                @click.prevent="
                  saveAxios({
                    url: ogrnFile.url,
                    label: ogrnFile.name
                  })
                "
                >{{ ogrnFile.name }}</a
              >
            </v-col>
            <v-col cols="2">
              <v-btn
                v-if="!disabledForm"
                color="red"
                icon
                @click="removeFile('OGRN')"
              >
                <v-icon>fas fa-times</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </div>
        <v-file-input
          v-if="!isOgrnLoad"
          v-model="ogrnLoad"
          :label="
            isCompany
              ? 'ОГРН (свидетельство или лист записи в ЕГРЮЛ)'
              : 'Загрузите скан-копию ОГРНИП'
          "
          :loading="loadingOgrn"
          :rules="rules.non_empty"
          required
        ></v-file-input>
      </template>
      <template v-if="isCompany">
        <div v-if="isCharterLoad">
          <v-row>
            <v-col cols="12">
              Устав:
            </v-col>
            <v-col cols="10">
              <a
                target="_blank"
                @click.prevent="
                  saveAxios({
                    url: charterFile.url,
                    label: charterFile.name
                  })
                "
                >{{ charterFile.name }}</a
              >
            </v-col>
            <v-col cols="2">
              <v-btn
                v-if="!disabledForm"
                color="red"
                icon
                @click="removeFile('CHARTER')"
              >
                <v-icon>fas fa-times</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </div>
        <v-file-input
          v-if="!isCharterLoad"
          v-model="charterLoad"
          :loading="loadingCharter"
          :rules="rules.non_empty"
          label="Устав компании"
          required
        ></v-file-input>
        <div v-if="isPowersLoad">
          <v-row>
            <v-col cols="12">
              Полномочия ЕИО (протокол/решение):
            </v-col>
            <v-col cols="10">
              <a
                target="_blank"
                @click.prevent="
                  saveAxios({
                    url: powersFile.url,
                    label: powersFile.name
                  })
                "
                >{{ powersFile.name }}</a
              >
            </v-col>
            <v-col cols="2">
              <v-btn
                v-if="!disabledForm"
                color="red"
                icon
                @click="removeFile('POWERS')"
              >
                <v-icon>fas fa-times</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </div>
        <v-file-input
          v-if="!isPowersLoad"
          v-model="powersLoad"
          :loading="loadingPowers"
          :rules="rules.non_empty"
          label="Документы, подтверждающие полномочия ЕИО (протокол/решение)"
          required
        ></v-file-input>
      </template>

      <v-row v-if="isCompany" style="margin-top: 25px;">
        <v-col if="isCompany">
          <v-text-field
            :error-messages="errors.director_title"
            :rules="rules.director_title"
            :value="profile.director_title"
            label="Должность ЕИО"
            required
            @input="update('director_title', $event)"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            :error-messages="errors.director_last_name"
            :rules="rules.director_last_name"
            :value="profile.director_last_name"
            label="Фамилия"
            required
            @input="update('director_last_name', $event)"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            :error-messages="errors.director_first_name"
            :rules="rules.director_first_name"
            :value="profile.director_first_name"
            label="Имя"
            required
            @input="update('director_first_name', $event)"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            :error-messages="errors.director_middle_name"
            :rules="rules.director_middle_name"
            :value="profile.director_middle_name"
            label="Отчество"
            required
            @input="update('director_middle_name', $event)"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row v-if="isPerson">
        <v-col md="4">
          <v-text-field
            :error-messages="errors.last_name"
            :rules="rules.last_name"
            :value="profile.last_name"
            label="Фамилия"
            required
            @input="update('last_name', $event)"
          ></v-text-field>
        </v-col>
        <v-col md="4">
          <v-text-field
            :error-messages="errors.first_name"
            :rules="rules.first_name"
            :value="profile.first_name"
            label="Имя"
            required
            @input="update('first_name', $event)"
          ></v-text-field>
        </v-col>
        <v-col md="4">
          <v-text-field
            :error-messages="errors.middle_name"
            :rules="rules.middle_name"
            :value="profile.middle_name"
            label="Отчество"
            required
            @input="update('middle_name', $event)"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row v-if="isPerson">
        <v-col cols="12" md="6" sm="6">
          <v-text-field
            :error-messages="errors.inn"
            :rules="rules.inn"
            :value="profile.inn"
            counter
            label="ИНН"
            required
            maxlength="12"
            @input="update('inn', $event)"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" sm="6">
          <v-text-field
            :error-messages="errors.snils"
            :rules="rules.snils"
            :value="profile.snils"
            counter
            label="СНИЛС"
            maxlength="11"
            required
            @input="update('snils', $event)"
          ></v-text-field>
        </v-col>
      </v-row>
      <!--      v-if="isPerson || isIP || isCompany"-->
      <template>
        <div
          v-if="isCompany"
          class="h6"
          style="margin-top: 20px;margin-bottom: 20px;"
        >
          Паспортные данные ЕИО
        </div>
        <div v-else class="h6" style="margin-top: 20px;margin-bottom: 20px;">
          Паспортные данные
        </div>

        <v-row v-if="isPerson || isIP" class="d-block d-md-flex">
          <v-col cols="12" md="4" sm="6">
            <v-text-field
              :error-messages="errors.passport_serial"
              :rules="rules.passport_serial"
              :value="profile.passport_serial"
              counter
              label="Серия"
              maxlength="4"
              required
              @input="update('passport_serial', $event)"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" md="4" sm="6">
            <v-text-field
              :error-messages="errors.passport_number"
              :rules="rules.passport_number"
              :value="profile.passport_number"
              counter
              label="Номер"
              maxlength="6"
              required
              @input="update('passport_number', $event)"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" md="4" sm="6">
            <v-dialog
              ref="dialog1"
              v-model="modalDatePassport"
              :return-value.sync="profile.passport_issue_date"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="profile.passport_issue_date"
                  v-bind="attrs"
                  v-on="on"
                  :rules="rules.non_empty"
                  label="Дата выдачи"
                  prepend-icon="fa-calendar"
                  readonly
                  required
                ></v-text-field>
              </template>
              <v-date-picker v-model="profile.passport_issue_date" scrollable>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="modalDatePassport = false">
                  Отмена
                </v-btn>
                <v-btn
                  color="primary"
                  text
                  @click="$refs.dialog1.save(profile.passport_issue_date)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-col>
        </v-row>
        <v-row></v-row>
        <!--        :item-text="issuedText"-->
        <v-autocomplete
          v-if="isPerson || isIP"
          v-model="issuedData"
          item-text="value"
          :items="issuedItems"
          :loading="loadingIssued"
          :rules="rules.issued"
          :search-input.sync="searchIssued"
          flat
          hide-no-data
          label="Кем выдан (введите название или код подразделения)"
          no-filter
          persistent-hint
          required
          return-object
        ></v-autocomplete>

        <v-row v-if="isPerson || isIP">
          <v-col cols="12" md="6" sm="6">
            <v-dialog
              ref="dialog2"
              v-model="modalDateBirthday"
              :return-value.sync="profile.birthday"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="profile.birthday"
                  v-bind="attrs"
                  v-on="on"
                  :rules="rules.non_empty"
                  label="Дата рождения"
                  prepend-icon="fa-calendar"
                  readonly
                  required
                ></v-text-field>
              </template>
              <v-date-picker v-model="profile.birthday" scrollable>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="modalDateBirthday = false">
                  Отмена
                </v-btn>
                <v-btn
                  color="primary"
                  text
                  @click="$refs.dialog2.save(profile.birthday)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-col>
          <v-col cols="12" md="6" sm="6">
            <v-text-field
              :rules="rules.non_empty"
              v-model="profile.address_birthday"
              label="Место рождения"
              required
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row v-if="isPerson || isIP">
          <v-col>
            <v-autocomplete
              v-model="addressLegalData"
              :items="addressLegalItems"
              :loading="loadingAddressLegal"
              :rules="rules.addressLegal"
              :search-input.sync="searchAddressLegal"
              class="autocomplete-wrap-text"
              flat
              hide-no-data
              item-text="value"
              label="Адрес регистрации"
              no-filter
              persistent-hint
              required
              return-object
            ></v-autocomplete>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="6">
            <div v-if="isPassport1Load">
              <v-row>
                <v-col cols="12">
                  Скан 2-3 страницы паспорта:
                </v-col>
                <v-col cols="10">
                  <a
                    target="_blank"
                    @click.prevent="
                      saveAxios({
                        url: passportFile1.url,
                        label: passportFile1.name
                      })
                    "
                    >{{ passportFile1.name }}</a
                  >
                </v-col>
                <v-col cols="2">
                  <v-btn
                    v-if="!disabledForm"
                    color="red"
                    icon
                    @click="removeFile('PASSPORT_1')"
                  >
                    <v-icon>fas fa-times</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </div>
            <v-file-input
              v-if="!isPassport1Load"
              v-model="passport23"
              :loading="loadingPassport23"
              :rules="rules.non_empty"
              label="Скан 2-3 страницы паспорта"
              required
            ></v-file-input>
          </v-col>
          <v-col cols="12" md="6">
            <div v-if="isPassport2Load">
              <v-row>
                <v-col cols="12">
                  Скан 4-5 страницы паспорта:
                </v-col>
                <v-col cols="10">
                  <a
                    target="_blank"
                    @click.prevent="
                      saveAxios({
                        url: passportFile2.url,
                        label: passportFile2.name
                      })
                    "
                    >{{ passportFile2.name }}</a
                  >
                </v-col>
                <v-col cols="2">
                  <v-btn
                    v-if="!disabledForm"
                    color="red"
                    icon
                    @click="removeFile('PASSPORT_2')"
                  >
                    <v-icon>fas fa-times</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </div>
            <v-file-input
              v-if="!isPassport2Load"
              v-model="passport45"
              :loading="loadingPassport45"
              :rules="rules.non_empty"
              label="Скан 4-5 страницы паспорта"
              required
            ></v-file-input>
          </v-col>
        </v-row>
      </template>

      <div class="h6 mt-6" style="margin-bottom: 20px;">
        Банковские реквизиты для перевода
      </div>

      <v-row>
        <v-col cols="12" md="6" sm="6">
          <!--            :rules="rules.bank_number"-->

          <v-text-field
            :error-messages="errors.bank_number"
            :rules="rules.bank_number"
            :value="profile.bank_number"
            counter
            label="Расчетный счет получателя"
            maxlength="20"
            required
            inputmode="numeric"
            @input="update('bank_number', $event)"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" sm="6">
          <v-autocomplete
            v-model="bankData"
            :item-text="bicText"
            :items="bankItems"
            :loading="loadingBank"
            :rules="rules.bank_bik"
            :search-input.sync="searchBank"
            flat
            hide-no-data
            hint="Введите название или БИК банка"
            label="БИК банка получателя"
            no-filter
            required
            return-object
          ></v-autocomplete>
        </v-col>
      </v-row>
      <template v-if="isPro">
        <v-btn
          :loading="submitLoading"
          required="true"
          class="float-right mt-2"
          color="primary"
          @click="updateProfile"
        >
          Сохранить
        </v-btn>
      </template>
      <template v-else>
        <div v-if="!disabledForm" class="text-right mt-2 mb-2">
          Подтвердите согласие на присоединение к договору СМС-кодом
        </div>

        <v-row v-if="!disabledForm">
          <v-col></v-col>
          <v-col>
            <v-row>
              <v-col style="text-align:right">
                <a
                  class="sendSMS"
                  color="primary"
                  @click="sendSMS"
                  :loading="smsCodeLoading"
                  style="width: 100%"
                  :disabled="smsCodeIsSuccess"
                  >Запросить СМС</a
                >
              </v-col>
              <v-col>
                <v-text-field
                  :error-messages="errors.sms_code"
                  refs="sms_code"
                  :rules="rules.sms_code"
                  v-model="profile.sms_code"
                  maxlength="4"
                  counter
                  type="number"
                  inputmode="numeric"
                  label="СМС-код"
                  outlined
                  dense
                  autocomplete="one-time-code"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <div
          v-if="smsCodeIsSuccess && !disabledForm"
          class="text-right"
          style="font-size: 80%;"
        >
          {{ smsCodeResponce }}
        </div>

        <v-btn
          v-if="!disabledForm"
          :disabled="!smsCodeIsSent"
          :loading="submitLoading"
          required="true"
          class="float-right mt-2"
          color="primary"
          @click="updateProfile"
        >
          Присоединиться к договору
        </v-btn>

        <div class="text-right mb-3" v-if="showContractAcceptedForCheck">
          <b>Договор отправлен на проверку.</b> <br />
          Об изменении статуса договора мы сообщим вам в интерфейсе Агентского
          кабинета.
        </div>

        <div style="font-style: italic;" class="text-right" v-if="disabledForm">
          Если вам необходимо изменить юридические данные, обратитесь к своему
          менеджеру
          <br />
          <router-link
            to="Feedback"
            title="Оставьте обратную связь,
     мы обязательно учтем ваши комментарии и пожелания"
            >Обратная связь
          </router-link>
        </div>
      </template>
    </v-form>
  </div>
</template>

<script>
import { saveAs } from "file-saver";
export default {
  name: "PersonalDataForm",
  components: {},
  data: () => ({
    toggleClicked: false,
    agentTypes: [
      {
        text: "Юридическое лицо",
        type: 3,
        disable: false
      },
      {
        text: "Индивидуальный предприниматель",
        type: 2,
        disable: false
      },
      {
        text: "Физическое лицо",
        type: 1,
        disable: false
      }
    ],
    nalogTypes: [
      {
        type: 1,
        name:
          "Упрощённая система налогообложения, в связи с чем услуги не облагаются НДС"
      },
      {
        type: 2,
        name:
          "Общая системе налогообложения, в связи с чем услуги Исполнителя облагаются НДС"
      },
      {
        type: 3,
        name: "Иная система налогообложения"
      }
    ],
    valid: true,
    disabledForm: false,
    smsCodeIsSent: false, // нажата кнопка "запрос смс"
    smsCodeLoading: false, // получаем статус отправки смс (нужен для крутилки)
    smsCodeIsSuccess: false, //статус отправки смс ок (отправлено на мобильник успешно)
    smsCodeResponce: "",
    submitLoading: false, // кнопка отправить нажата (нужен для крутилки)
    // updateProfileSuccessful: false, // форма сохранена успешно (нужено для сообщения сохранено в про-форме)

    showContractAcceptedForCheck: false, // показываем текст после отправки формы, пока статус "заявка"
    showContractIsSigned: false, //  договор уже подписан
    showContractDecline: false, // статус договора "отклонен"
    showContractNeedsFix: false, // стутус требует исправления.
    modalDateBirthday: false,
    modalDatePassport: false,
    issuedData: {
      data: {
        code: "",
        name: ""
      },
      value: ""
    },
    bankData: {
      data: {
        address: {
          unrestricted_value: ""
        },
        correspondent_account: "",
        bic: "",
        name: {
          payment: ""
        }
      }
    },
    addressLegalData: {},
    addressBirthdayData: {},
    companyData: {},
    searchIssued: null,
    searchBank: null,
    searchCompany: null,
    searchAddressLegal: null,
    searchAddressBirthday: null,
    addressLegalString: "",
    loadingIssued: false,
    loadingBank: false,
    loadingCompany: false,
    loadingAddressLegal: false,
    loadingAddressBirthday: false,
    issuedItems: [],
    bankItems: [],
    companyItems: [],
    addressLegalItems: [],
    addressBirthdayItems: [],
    profile: {
      sms_code: null,
      first_name: null,
      last_name: null,
      middle_name: null,
      director_first_name: null,
      director_last_name: null,
      director_middle_name: null,
      director_title: null,
      inn: null,
      nalog_type: 1,
      ogrn: null,
      snils: null,
      birthday: null,
      passport_serial: null,
      passport_number: null,
      passport_issue_date: null,
      passport_issued: null,
      passport_issued_code: null,
      bank_number: null,
      bank_bik: null,
      bank_name: null,
      bank_address: null,
      bank_corr: null,
      company: null,
      address: null,
      address_legal: null,
      address_birthday: null,
      registration_date: null
    },
    errors: {
      first_name: [],
      last_name: [],
      middle_name: [],
      director_first_name: [],
      director_last_name: [],
      director_middle_name: [],
      inn: [],
      ogrn: [],
      snils: [],
      passport_serial: [],
      passport_number: [],
      passport_issue_date: [],
      passport_issued: [],
      bank_number: [],
      bank_bik: [],
      bank_name: [],
      bank_address: [],
      bank_corr: [],
      birthday: [],
      passport_issued_code: [],
      company: [],
      sms_code: []
    },
    rules: {
      non_empty: [v => !!v || "Обязательное для заполнения поле"],
      first_name: [v => !!v || "Введите имя"],
      last_name: [v => !!v || "Введите фамилию"],
      middle_name: [v => !!v || "Введите отчество"],

      director_title: [v => !!v || "Введите должность"],
      director_first_name: [v => !!v || "Введите имя"],
      director_last_name: [v => !!v || "Введите фамилию"],
      director_middle_name: [v => !!v || "Введите отчество"],
      // inn:null,
      inn: [v => (!!v && v.length === 12) || "ИНН введен некорректно"], // используется только для физиков
      // companyData: [
      //   v =>
      //     (!!v && !!v.data && v.data.inn.length === 10) ||
      //     "ИНН введен не корректно2"
      // ],
      ogrn: [v => !!v || "Введите ОГРН"],
      snils: [v => (!!v && v.length === 11) || "СНИЛС введен некорректно"],
      passport_serial: [
        v => !!v || "Введите серию паспорта",
        v => (!!v && v.length === 4) || "Введите серию паспорта"
      ],
      passport_number: [
        v => !!v || "Введите номер паспорта",
        v => (!!v && v.length === 6) || "Введите номер паспорта"
      ],
      passport_issue_date: null,
      passport_issued: null,
      bank_number: [
        v => (!!v && v.length === 20) || "Счет введен не корректно"
      ],
      // sms_code: [ v => !!v || "Код состоит из 4 цифр."],
      // sms_code: [ v => v.length === 4 || "Код состоит из 4 цифр."],
      sms_code: [true],

      bank_bik: [v => !!v.value || "Укажите БИК банка"],
      bank_name: null,
      bank_address: null,
      bank_corr: null,
      company: null,
      birthday: [],
      passport_issued_code: [],
      issued: [v => !!v.value || "Укажите кем выдан"],
      addressLegal: [
        v => !!v.value || !!v.length || "Укажите адрес регистрации"
      ],
      addressBirthday: [v => !!v.value || "Укажите адрес рождения"]
    },
    files: [],
    loadingPassport23: false,
    loadingPassport45: false,
    loadingInn: false,
    loadingOgrn: false,
    loadingCharter: false,
    loadingPowers: false,
    passport23: null,
    passport45: null,
    innLoad: null,
    charterLoad: null,
    powersLoad: null,
    ogrnLoad: null,
    innPersonLength: 12,
    innCompanyLength: 10
  }),
  computed: {
    isPro() {
      return this.$store.state.user.is_pro;
    },
    agentType() {
      return this.$store.state.user.type;
    },
    agentState() {
      return this.$store.state.user.state;
    },
    isPerson: function() {
      return this.agentType === 1;
    },
    isIP: function() {
      return this.agentType === 2;
    },
    isCompany: function() {
      return this.agentType === 3;
    },
    dadataKey: function() {
      return process.env.VUE_APP_DADATA_KEY;
    },
    isPassport1Load: function() {
      return this.files["PASSPORT_1"] !== undefined;
    },
    passportFile1: function() {
      return this.files["PASSPORT_1"];
    },
    isPassport2Load: function() {
      return this.files["PASSPORT_2"] !== undefined;
    },
    passportFile2: function() {
      return this.files["PASSPORT_2"];
    },
    isInnLoad: function() {
      return this.files["INN"] !== undefined;
    },
    innFile: function() {
      return this.files["INN"];
    },
    isOgrnLoad: function() {
      return this.files["OGRN"] !== undefined;
    },
    ogrnFile: function() {
      return this.files["OGRN"];
    },
    isCharterLoad: function() {
      return this.files["CHARTER"] !== undefined;
    },
    charterFile: function() {
      return this.files["CHARTER"];
    },
    isPowersLoad: function() {
      return this.files["POWERS"] !== undefined;
    },
    powersFile: function() {
      return this.files["POWERS"];
    },
    downloadContractURL() {
      var file = this.files["CONTRACT_SIGNED"];
      return file.url ?? "";
    }
  },
  watch: {
    "this.$store.state.user.contract_state": {
      immediate: true,
      handler() {
        this.ifFormDisabled();
      }
    },

    searchIssued(val) {
      val && val !== this.issuedData.name && this.queryIssuedSelections(val);
    },
    searchBank(val) {
      val &&
        val !== this.profile.bank_bik + ", " + this.profile.bank_name &&
        this.queryBankSelections(val);
    },
    searchAddressLegal(val) {
      val && this.queryAddressLegal(val);
    },
    searchAddressBirthday(val) {
      val && this.queryAddressBirthday(val);
    },
    searchCompany(val) {
      val && val !== this.profile.inn && this.queryCompanySelections(val);
    },
    issuedData(val) {
      this.profile.passport_issued = val.data.name;
      this.profile.passport_issued_code = val.data.code;
    },
    addressLegalData(val) {
      this.profile.address_legal = val.value;
    },
    addressBirthdayData(val) {
      this.profile.address_birthday = val;
    },
    bankData(val) {
      this.profile.bank_address = val.data.address.unrestricted_value;
      this.profile.bank_name = val.data.name.payment;
      this.profile.bank_corr = val.data.correspondent_account;
      this.profile.bank_bik = val.data.bic;
    },
    companyData(val) {
      if (val) {
        this.profile.inn = val.data.inn;
        this.profile.ogrn = val.data.ogrn;
        this.profile.company = val.value;
        this.profile.address = val.data.address.value;
        this.profile.address_legal = val.data.address.value;
        this.profile.registration_date = val.data.state.registration_date;

        if (this.isIP) {
          let fio = val.data.name.full.split(" ");
          this.profile.last_name = fio[0];
          this.profile.first_name = fio[1];
          this.profile.middle_name = fio[2];
        }

        if (this.isCompany) {
          let fio = val.data.management.name.split(" ");
          this.profile.director_last_name = fio[0];
          this.profile.director_first_name = fio[1];
          this.profile.director_middle_name = fio[2];
        }
      }
    },
    passport23(val) {
      if (val === undefined) {
        this.removeFile("PASSPORT_1");
      } else {
        this.uploadFile(val, "PASSPORT_1");
      }
    },
    passport45(val) {
      if (val === undefined) {
        this.removeFile("PASSPORT_2");
      } else {
        this.uploadFile(val, "PASSPORT_2");
      }
    },
    innLoad(val) {
      if (val === undefined) {
        this.removeFile("INN");
      } else {
        this.uploadFile(val, "INN");
      }
    },
    ogrnLoad(val) {
      if (val === undefined) {
        this.removeFile("OGRN");
      } else {
        this.uploadFile(val, "OGRN");
      }
    },
    charterLoad(val) {
      if (val === undefined) {
        this.removeFile("CHARTER");
      } else {
        this.uploadFile(val, "CHARTER");
      }
    },
    powersLoad(val) {
      if (val === undefined) {
        this.removeFile("POWERS");
      } else {
        this.uploadFile(val, "POWERS");
      }
    }
  },
  methods: {
    // если агент заполнил один тип, а потом переключился на другой  (ЮР в ИП),
    // то надо очистить поля, а то могут быть косяки
    emptyProfileNames() {
      this.profile.first_name = "";
      this.profile.last_name = "";
      this.profile.middle_name = "";
      this.profile.director_first_name = "";
      this.profile.director_last_name = "";
      this.profile.director_middle_name = "";
      this.profile.director_title = "";
    },

    changeAgentType(item) {
      this.emptyProfileNames();
      this.$http({
        url: "/agents/profile/change-type?type=" + item.type,
        method: "GET"
      })
        .catch(err => {
          console.log(err);
          // console.log(err.response);
        })
        .then(() => {
          this.$store.dispatch("user", {});
        });
    },
    companyText(v) {
      const inn = v.data ? v.data.inn : "";
      return inn + ", " + v.value;
    },
    issuedText: function(v) {
      return v.data.code + ", " + v.data.name;
    },
    bicText: function(v) {
      return v.data.bic + ", " + v.value;
    },
    removeFile: function(type) {
      let that = this;
      let fileId = this.files[type].id;
      let newFiles = { ...this.files };
      delete newFiles[type];
      this.files = newFiles;
      this.$http
        .get("/agents/files/delete?id=" + fileId)
        .catch(function(err) {
          console.log(err);
        })
        .finally(() => that.getFiles());
    },
    uploadFile(val, type) {
      this.changeLoadingFile(type);
      let formData = new FormData();
      let that = this;
      formData.append("file", val);
      formData.append("type", type);
      this.$http
        .post("/agents/files/upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(function() {
          that.getFiles();
        })
        .catch(function(err) {
          console.log(err);
        })
        .finally(() => this.changeLoadingFile(type));
    },
    queryIssuedSelections(v) {
      this.loadingIssued = true;
      this.$http({
        url: "/api/dadata/outward?query=" + v,
        method: "GET"
      })
        .then(res => {
          this.issuedItems = res.data.suggestions;
        })
        .catch(err => {
          console.log(err);
          console.log(err.response);
        })
        .finally(() => (this.loadingIssued = false));
    },
    queryAddressLegal(v) {
      this.loadingAddressLegal = true;
      this.$http({
        url: "/api/dadata/address?query=" + v,
        method: "GET"
      })
        .then(res => {
          this.addressLegalItems = res.data.suggestions;
        })
        .catch(err => {
          console.log(err);
          console.log(err.response);
        })
        .finally(() => (this.loadingAddressLegal = false));
    },
    queryAddressBirthday(v) {
      this.loadingAddressBirthday = true;
      this.$http({
        url: "/api/dadata/address?query=" + v,
        method: "GET"
      })
        .then(res => {
          this.addressBirthdayItems = res.data.suggestions;
        })
        .catch(err => {
          console.log(err);
          console.log(err.response);
        })
        .finally(() => (this.loadingAddressBirthday = false));
    },
    queryBankSelections(v) {
      this.loadingBank = true;
      this.$http({
        url: "/api/dadata/bank?query=" + v,
        method: "GET"
      })
        .then(res => {
          this.bankItems = res.data.suggestions;
        })
        .catch(err => {
          console.log(err);
          console.log(err.response);
        })
        .finally(() => (this.loadingBank = false));
    },
    queryCompanySelections(v) {
      this.loadingCompany = true;
      this.$http({
        url: "/api/dadata/party-suggest?query=" + v,
        method: "GET"
      })
        .then(res => {
          // console.log(res);
          this.companyItems = res.data.suggestions;
        })
        .catch(err => {
          console.log(err);
          console.log(err.response);
        })
        .finally(() => (this.loadingCompany = false));
    },
    update(key, value) {
      this.profile[key] = value;
    },

    ifFormDisabled() {
      var val = this.$store.state.user.contract_state;
      if (val && val !== 4) {
        this.disabledForm = true;
      }

      // если договор в статусе "заявка", соответствующая подпись
      if (val == 1) {
        this.showContractAcceptedForCheck = true;
      }
      // если договор в статусе "подписан",
      if (val == 2) {
        this.showContractIsSigned = true;
      }
      // если договор в статусе "отклонен",
      if (val == 3) {
        this.showContractDecline = true;
      }
      // если договор в статусе "отклонен для дополнения",
      if (val == 4) {
        this.showContractNeedsFix = true;
      }
    },
    dispatchUser() {
      this.$store
        .dispatch("user", {})
        .then(() => {
          this.ifFormDisabled();
        })
        .catch(err => {
          console.log('this.$store.dispatch("user")', err);
        });
    },
    getProfile() {
      // this.disabledForm = true;
      this.$http({
        url: "/agents/profile/view",
        method: "GET"
      }).then(resp => {
        this.profile = resp.data;
        this.loadDefaultValues();

        // this.ifFormDisable();
      });
    },
    loadDefaultValues() {
      if (this.profile.passport_issued) {
        this.issuedData = {
          value: this.profile.passport_issued,
          data: {
            name: this.profile.passport_issued,
            code: this.profile.passport_issued_code
          }
        };
        this.issuedItems.push(this.issuedData);
      }
      if (this.profile.bank_bik) {
        this.bankData = {
          data: {
            address: {
              unrestricted_value: this.profile.bank_address
            },
            correspondent_account: this.profile.bank_corr,
            bic: this.profile.bank_bik,
            name: {
              payment: this.profile.bank_name
            }
          },
          value: this.profile.bank_name
        };
        this.bankItems.push(this.bankData);
      }
      if (this.profile.inn) {
        this.companyData = {
          data: {
            inn: this.profile.inn,
            ogrn: this.profile.ogrn,
            name: {
              full:
                this.profile.last_name +
                " " +
                this.profile.first_name +
                " " +
                this.profile.middle_name
            },
            management: {
              name:
                this.profile.last_name +
                " " +
                this.profile.first_name +
                " " +
                this.profile.middle_name
            }
          },
          value: this.profile.company
        };
        this.companyItems.push(this.companyData);
      }
      if (this.profile.address_legal) {
        this.addressLegalData = this.profile.address_legal;
        this.addressLegalItems.push(this.addressLegalData);
      }
      if (this.profile.address_birthday) {
        this.addressBirthdayData = this.profile.address_birthday;
        this.addressBirthdayItems.push(this.addressBirthdayData);
      }
    },
    getFiles() {
      // this.disabledForm = true;
      this.$http({
        url: "/agents/files/list",
        method: "GET"
      }).then(resp => {
        this.files = resp.data;
        // this.disabledForm = false;
      });
    },
    updateProfile() {
      if (!this.$refs.form.validate()) {
        this.$store.commit(
          "showBottomSheetMessage",
          "Необходимо правильно заполнить все поля формы"
        );
        return;
      } else {
        //очищаем предыдущие ошибки
        Object.keys(this.errors).forEach(key => {
          this.errors[key] = [];
        });
      }
      this.submitLoading = true;

      this.$http({
        url: "/agents/profile/update",
        method: "PUT",
        data: this.profile
      })
        .then(resp => {
          // форма успешно сохранена на сервере, смс и поля в порядке
          this.profile = resp.data;
          this.loadDefaultValues();
          this.submitLoading = false;

          if (this.isPro) {
            // this.updateProfileSuccessful = true;
            this.$store.commit(
              "showBottomSheetMessage",
              "Профиль успешно сохранен"
            );
          } else {
            this.disabledForm = true;
            this.showContractAcceptedForCheck = true;
          }
        })
        .catch(err => {
          // TODO обработка ошибок не толкьо формы, но и эксепшн (когда что-то поломалось)
          console.log(err);
          this.addErrors(err.response.data);
          let errMsg = "";
          if (Array.isArray(err.response.data)) {
            err.response.data.forEach(i => (errMsg += i?.message + "; "));
          }

          this.submitLoading = false;
          this.$store.commit(
            "showBottomSheetMessage",
            "Ошибка сохранения! " + errMsg
          );
        });
    },
    changeType: function() {
      let user = { ...this.$store.state.user };
      user.type = null;
      this.$store.commit("updateUser", user);
    },
    addErrors: function(errors) {
      errors.forEach(item => {
        this.errors[item.field].push(item.message);
      });
    },
    changeLoadingFile(type) {
      if (type === "PASSPORT_1") {
        this.loadingPassport23 = !this.loadingPassport23;
        if (this.loadingPassport23) {
          this.passport23 = null;
        }
      }
      if (type === "PASSPORT_2") {
        this.loadingPassport45 = !this.loadingPassport45;
        if (this.loadingPassport45) {
          this.passport45 = null;
        }
      }
      if (type === "INN") {
        this.loadingInn = !this.loadingInn;
        if (this.loadingInn) {
          this.innFile = null;
        }
      }
      if (type === "OGRN") {
        this.loadingOgrn = !this.loadingOgrn;
        if (this.loadingOgrn) {
          this.ogrnFile = null;
        }
      }
      if (type === "CHARTER") {
        this.loadingCharter = !this.loadingCharter;
        if (this.loadingCharter) {
          this.charterFile = null;
        }
      }
      if (type === "POWERS") {
        this.loadingPowers = !this.loadingPowers;
        if (this.loadingPowers) {
          this.powersFile = null;
        }
      }
    },
    profile_registration_date() {
      if (this.profile.registration_date > 10000) {
        return new Date(this.profile.registration_date).toLocaleDateString();
      } else return "";
    },
    sendSMS() {
      this.errors.sms_code = [];
      if (this.$refs.form.validate()) {
        this.smsCodeLoading = true;
        this.$http({
          url: "/agents/profile/send-sms",
          method: "POST"
        })
          .then(resp => {
            console.log("sms ok!");
            this.smsCodeIsSent = true;
            this.smsCodeResponce = resp.data.message;
            this.smsCodeLoading = false;
            this.smsCodeIsSuccess = true;
            setTimeout(() => {
              this.$set(this.rules, "sms_code", [
                v => !!v || "Код состоит из 4 цифр."
              ]);

              // что-то не работает перевод фокуса, а было бы удобно
              // this.$refs.sms_code.input.focus();
              // this.$refs.sms_code.$parent.$el['1'].focus();
            }, 2000);
          })
          .catch(err => {
            this.smsCodeLoading = false;
            // let error = err.data.message;
            // alert(error);
            console.log("sms err!");
            this.errors["sms_code"].push(err.data.message);
          });
      } else {
        this.$store.commit(
          "showBottomSheetMessage",
          "Перед подписанием СМС, необходимо заполнить все поля формы"
        );
      }
    },
    // https://thewebdev.info/2021/05/22/how-ow-to-download-a-file-in-the-browser-with-vue-js/
    // для скачивания нужно передать заголовок Authorization Bearer,
    // поэтому просто ссылка не работает (перекидывает на логин)
    saveAxios({ url, label }) {
      this.$http({
        url: url,
        method: "GET",
        responseType: "blob"
      }).then(response => {
        saveAs(response.data, label);
      });
    }
  },
  created() {
    this.getProfile();
    this.getFiles();
  },
  mounted() {
    // статус договора мог изменится, и возможно надо блочить форму.
    // для этого переполучаем его профиль
    this.dispatchUser();
  },
  filters: {
    timestamp(val) {
      return new Date(val).toLocaleDateString();
    }
  }
};
</script>

<style scoped>
.sendSMS {
  color: var(--v-anchor-base);
  font-weight: bold;
  border-bottom: 1px dashed;
  cursor: pointer;
  /*display: inline-block;*/
}

.contract_status_info {
  margin: 20px 0;
  padding: 20px;
  background-color: #f2f2f2;
  font-size: 110%;
}

.h6 {
  margin-top: 15px;
  font-size: 70%;
  color: grey;
  border-bottom: 1px dotted;
}
</style>
