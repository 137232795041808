<template>
  <v-container>
    <h2>Создание профиля в агентском кабинете &laquo;МСБ-Лизинг&raquo;</h2>
    <v-breadcrumbs
      :items="[
        {
          text: 'Главная',
          disabled: false,
          href: '/'
        },
        {
          text: 'Регистрация',
          disabled: true
        }
      ]"
      class="breadcrumbs"
    ></v-breadcrumbs>
    <!--    @submit.prevent="formSubmit"-->
    <v-form
      class="mt-5 ma-auto"
      style="max-width: 500px"
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <v-text-field
        v-model="name"
        :rules="nameRules"
        label="Имя агента"
        required
        :error-messages="nameErrors"
      ></v-text-field>
      <v-text-field
        v-model="surname"
        label="Фамилия агента"
        :error-messages="nameErrors"
      ></v-text-field>

      <MaskedPhoneInput
        v-model="phone"
        :errors="phoneErrors"
      ></MaskedPhoneInput>
      <v-text-field
        v-model="code"
        label="Код подтверждения"
        required
        :rules="codeRules"
        v-if="isCodeSend"
        :error-messages="codeErrors"
      ></v-text-field>

      <cfturnstile :sitekey="turnstile_site_key" @verify="captchaVerify" />
      <span class="red--text" v-if="captchaError"
        >Проблемы с проверкой CAPTCHA. Обновите страницу</span
      >

      <v-btn
        color="primary"
        class="mt-3 mr-4"
        @click="sendCode"
        v-if="!isCodeSend"
      >
        Отправить код
      </v-btn>
      <v-checkbox
        v-model="checkbox"
        :rules="[v => !!v || 'Подтвердите согласие']"
        required
      >
        <template v-slot:label>
          <div style="font-size: 90%;">
            Ознакомлен с
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <a target="_blank" href="" rel="noopener noreferrer" v-on="on">
                </a>
              </template>
              Открыть в новом окне
            </v-tooltip>
            <a
              @click.stop
              target="_blank"
              href="https://msb-leasing.ru/company/privacy"
            >
              политикой обработки персональных данных
            </a>
            и <br />
            <router-link to="/agreement_personal">
              согласен на обработку персональных данных
            </router-link>
          </div>
        </template>
      </v-checkbox>

      <v-checkbox
        style="margin-top: -5px;"
        v-model="checkbox2"
        :rules="[v => !!v || 'Подтвердите согласие']"
        required
      >
        <template v-slot:label>
          <div style="font-size: 90%;">
            Ознакомлен c
            <router-link to="/agreement">
              пользовательским соглашением</router-link
            >
          </div>
        </template>
      </v-checkbox>

      <v-btn color="primary" class="mr-4" @click="signUp" v-if="isCodeSend">
        Регистрация
      </v-btn>

      <v-btn color="primary" text to="/login">
        Я уже зарегистрирован
      </v-btn>
    </v-form>
  </v-container>
</template>

<script>
import MaskedPhoneInput from "../components/MaskedPhoneInput";
import ym from "../util/ym";
import Turnstile from "cfturnstile-vue2";

export default {
  name: "SignUp",
  components: { MaskedPhoneInput, cfturnstile: Turnstile },
  data: () => ({
    captchaToken: "",
    valid: true,
    checkbox: true,
    checkbox2: true,
    name: "",
    surname: "",
    phone: "",
    code: "",
    phoneErrors: [],
    codeErrors: [],
    nameErrors: [],
    isCodeSend: false,
    captchaError: false,
    nameRules: [v => !!v || "Введите имя"],
    codeRules: [v => !!v || "Введите код"]
  }),
  methods: {
    handleYm: ym,
    captchaVerify(token) {
      this.captchaToken = token;
    },
    sendCode() {
      this.phoneErrors = [];
      if (this.$refs.form.validate()) {
        let phone = this.phone;
        let captcha = this.captchaToken;
        this.$store
          .dispatch("sendConfirmCode", { phone, captcha })
          .then(data => {
            console.log(data);
            this.isCodeSend = true;
          })
          .catch(err => {
            this.addErrors(err.data);
          });
        this.handleYm("send_code_registration");
      }
    },
    signUp() {
      if (this.$refs.form.validate()) {
        this.phoneErrors = [];
        this.codeErrors = [];
        let phone = this.phone;
        let code = this.code;
        let name = this.name + " " + this.surname;
        let captcha = this.captchaToken;
        this.$store
          .dispatch("register", {
            phone,
            code,
            name,
            captcha,
            referral: localStorage.getItem("referral") || ""
          })
          .then(() => this.$router.push("/create-leasing"))
          .catch(err => {
            this.addErrors(err.data);
          });
      }
    },
    addErrors(errors) {
      errors.forEach(item => {
        if (item.field === "phone") {
          this.phoneErrors.push(item.message);
        }
        if (item.field === "code") {
          this.codeErrors.push(item.message);
        }
        if (item.field === "name") {
          this.codeErrors.push(item.message);
        }
        if (item.field === "expire") {
          this.isCodeSend = true;
        }
        if (item.field === "captcha") {
          this.captchaError = true;
        }
      });
    }
  },
  mounted() {
    this.$store.commit("setBreadCrumbs", [
      {
        text: "Главная",
        disabled: false,
        href: "/"
      },
      {
        text: "Регистрация",
        disabled: true
      }
    ]);
  }
};
</script>

<style scoped></style>
