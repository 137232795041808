<template>
  <v-container>
    <h2 v-if="edit_id">Заявка на лизинг: редактирование</h2>
    <h2 v-else>Заявка на лизинг: создание</h2>
    <v-breadcrumbs
        :items="[
        {
          text: 'Главная',
          disabled: false,
          href: '/'
        },
        {
          text: 'Новая заявка +',
          disabled: true
        }
      ]"
        class="breadcrumbs"
    ></v-breadcrumbs>
    <v-form
        v-if="!isEmptyType"
        ref="form"
        v-model="valid"
        lazy-validation
        @submit.prevent="validate"
    >
      <v-row no-gutters class="mt-7">
        <v-col class="col-md-7">
          <v-select
              v-model="selectedSegmentGroupsId"
              :error-messages="errors.items"
              :items="segmentGroups"
              item-text="label"
              item-value="id"
              label="Выберите группу"
              :messages="
              selectedSegmentGroupsId === 5
                ? 'Данный вид лизинга доступен только для юр. лиц с НДС'
                : ''
            "
              name="selectedSegmentGroupsId"
              outlined
              dense
              @change="changeGroup"
          ></v-select>

          <v-row
              v-if="!isFormFull()"
              class="d-md-none"
              style="margin-top: -15px;margin-bottom: 11px;"
          >
            <v-col cols="4">
              <div
                  class="agentValue"
                  style="display: inline-block;width: 100px;"
              >
                {{ agentPercent }}&thinsp;%
              </div>
            </v-col>
            <v-col>
              <label class="primary--text agentLabel" style="margin: 0;">
                Ставка тарифа Агентского вознаграждения зависит от группы
                выбранного клиентом имущества. Подробнее о тарифах читайте в
                разделе
                <router-link to="Rates">"Тарифы выплат"</router-link>
                .</label
              >
            </v-col>
          </v-row>

          <v-select
              v-model="selectedItem"
              :error-messages="errors.items"
              :items="segments"
              :rules="rules.items"
              required
              item-text="name"
              item-value="id"
              label="Выберите тип имущества"
              name="items"
              outlined
              dense
              @change="calculateAPI"
          ></v-select>

          <v-text-field
              :error-messages="errors.itemName"
              :value="fields.itemName"
              name="itemName"
              maxlength="255"
              :label="
              selectedSegmentGroupsId == 5
                ? 'Укажите наименование недвижимости'
                : 'Укажите наименование, модель'
            "
              messages="Необязательно для заполнения"
              outlined
              dense
          ></v-text-field>
          <v-select
              v-if="selectedSegmentGroupsId != 4"
              v-model="advancePercent"
              :error-messages="errors.advancePercent"
              :items="advanceLabels"
              :rules="rules.advancePercent"
              name="advancePercent"
              :label="'Аванс (по умолчанию ' + advanceMin + '%)'"
              messages="Необязательно для заполнения"
              outlined
              dense
              @change="calculateAPI"
          ></v-select>
          <v-text-field
              name="term"
              v-model="term"
              messages="Необязательно для заполнения"
              :error-messages="errors.term"
              inputmode="numeric"
              :label="termLabel"
              :rules="[ruleTerm]"
              outlined
              dense
              maxlength="3"
          ></v-text-field>
          <span v-if="edit_id" class="edit_inn">Лизингополучатель: {{ companyData.data?.name?.short_with_opf }} ({{ companyData.data?.inn }})</span>
          <v-checkbox
              dense
              v-if="edit_id"
              style="margin-top: 0!important;"
              v-model="edit_inn"
              label="Изменить ИНН лизингополучателя?"
          ></v-checkbox>
          <v-autocomplete
              v-if="!edit_id || (edit_id && edit_inn)"
              :required="!edit_id || (edit_id && edit_inn)"
              outlined
              dense
              v-model="companyData"
              :item-text="companyText"
              :items="companyItems"
              :loading="loadingCompany"
              :search-input.sync="searchCompany"
              :rules="[ruleDadataCompany]"
              flat
              hide-no-data
              item-value="false"
              label="Название или ИНН организации"
              no-filter
              return-object
          ></v-autocomplete>

          <template v-if="fields.inn && !edit_id">
            <v-text-field
                :error-messages="errors.addressLegal"
                :rules="rules.addressLegal"
                :value="fields.addressLegal"
                disabled
                label="Адрес"
            ></v-text-field>
          </template>

          <v-text-field
              outlined
              dense
              :error-messages="errors.orgName"
              :rules="rules.orgName"
              :value="fields.orgName"
              class="d-none"
              hidden
              name="orgName"
          ></v-text-field>
          <v-text-field
              outlined
              dense
              :value="fields.dadata"
              class="d-none"
              hidden
              name="dadata"
          ></v-text-field>
          <v-text-field
              outlined
              dense
              :value="fields.guarantor_dadata"
              class="d-none"
              hidden
              name="guarantor_dadata"
          ></v-text-field>
          <v-text-field
              outlined
              dense
              :rules="rules.inn"
              :value="fields.inn"
              class="d-none"
              hidden
              name="inn"
              type="hidden"
              label="ИНН организации"
              maxlength="12"
          ></v-text-field>
          <v-text-field
              outlined
              dense
              :value="fields.addressLegal"
              class="d-none"
              hidden
              name="addressLegal"
          ></v-text-field>
          <v-text-field
              outlined
              dense
              :value="fields.type"
              class="d-none"
              hidden
              name="type"
          ></v-text-field>
          <v-text-field
              v-model="costFormated"
              name="cost"
              label="Укажите стоимость предмета лизинга"
              outlined
              required
              maxlength="11"
              dense
              inputmode="numeric"
              suffix="₽"
              :rules="[ruleCost]"
              :error-messages="errors.cost"
              @input="calculateAPI"
          ></v-text-field>

          <v-row
              v-if="!isFormFull()"
              style="margin-top: -16px;"
              class="d-block d-md-none"
          >
            <v-col>
              <div class="agentSum">
                <div
                    class="agentValue"
                    style="padding: 5px 15px;vertical-align: top;"
                >
                  {{ agentFee | formatNumber }}
                  &thinsp;&#8381;
                </div>

                <div
                    class="primary--text ml-2"
                    style="font-size: 12px;width: 60%;vertical-align: top;display: inline-block;line-height: 110%;"
                >
                  Предварительный расчет суммы Агентского вознаграждения.
                </div>
                <br/>
                <label
                    class="primary--text agentLabel"
                    style="line-height: 110%;"
                >
                  Сумма рассчитывается от суммы финансирования, которое клиент
                  получит при заключении сделки. <br/><br/>
                  Точная сумма финансирования будет определена в момент
                  формирования окончательных условий сделки с Клиентом.
                  <br/><br/>
                  Подробнее о расчете суммы Агентского вознаграждения читайте в
                  разделе
                  <router-link to="Rates">"Тарифы выплат"</router-link>
                </label>
                <br/>
              </div>
            </v-col>
          </v-row>

          <v-text-field
              outlined
              dense
              :error-messages="errors.fio"
              :rules="rules.fio"
              :value="fields.fio"
              label="Укажите имя контактного лица"
              name="fio"
              @change="errors.fio = []"
          ></v-text-field>
          <v-text-field
              outlined
              dense
              v-model="phone"
              :counter="10"
              :error-messages="errors.phone"
              :rules="rules.phone"
              label="Укажите телефон контактного лица"
              name="phone"
              prefix="+7"
              maxlength="10"
              required
              type="tel"
          ></v-text-field>

          <v-checkbox
              dense
              v-if="!edit_id || !GuarantorData"
              style="margin-top: 0!important;"
              v-model="edit_inn_guarantor"
              label="У клиента есть поручитель?"
          ></v-checkbox>
          <!--          поручитель -->
          <span v-if="GuarantorData.data && edit_id" class="edit_inn">Поручитель: {{ GuarantorData.data?.name?.short_with_opf }} ({{ GuarantorData.data?.inn }})</span>
          <v-checkbox
              dense
              v-if="edit_id"
              style="margin-top: 0!important;"
              v-model="edit_inn_guarantor"
              label="Изменить поручителя?"
          ></v-checkbox>

          <v-autocomplete
              outlined
              dense
              v-if="edit_inn_guarantor"
              v-model="GuarantorData"
              :item-text="companyText"
              :items="GuarantorItems"
              :loading="loadingGuarantor"
              :search-input.sync="searchGuarantor"
              flat
              hide-no-data
              item-value="false"
              label="Название или ИНН поручителя"
              no-filter
              return-object
              ref="autocompleteGuarantor"
          ></v-autocomplete>

          <v-textarea
              outlined
              dense
              name="message"
              label="Примечание"
              placeholder="Оставьте любую дополнительную информацию о клиенте, контакте или сделке, которая может быть важной для заключения сделки с клиентом или переговорах"
              v-model="fields.message"
              :error-messages="errors.message"
              hint="Необязательное поле"
              rows="4"
          ></v-textarea>

          <input type="hidden" name="advancePercent" :value="advancePercent"/>
          <input type="hidden" name="term" :value="term"/>
        </v-col>
        <v-col cols="5" class="d-none d-md-flex">
          <div v-if="!isFormFull()">
            <div class="agentProcent ml-12">
              <div class="agentValue">{{ agentPercent }}&thinsp;%</div>
              <br/>
              <label class="primary--text agentLabel">
                Ставка тарифа Агентского вознаграждения зависит от группы
                выбранного клиентом имущества. Подробнее о тарифах читайте в
                разделе
                <router-link to="Rates">"Тарифы выплат"</router-link>
                .</label
              >
              <br/>
            </div>

            <div class="agentSum ml-12" style="margin-top: 203px;">
              <div class="agentValue">
                {{ agentFee | formatNumber }}

                <span v-if="isFormFull()" style="font-size: 80%;">
                  (+{{ Math.round((agentFee * 0.25) / 100) | formatNumber }})
                </span>
                &thinsp;&#8381;
              </div>
              <br/>
              <label
                  class="primary--text agentLabel"
                  style="line-height: 110%;"
              >
                Предварительный расчет суммы Агентского вознаграждения. Сумма
                рассчитывается от суммы финансирования, которое клиент получит
                при заключении сделки. <br/><br/>
                Точная сумма финансирования будет определена в момент
                формирования окончательных условий сделки с Клиентом.
                <br/><br/>
                Подробнее о расчете суммы Агентского вознаграждения читайте в
                разделе
                <router-link to="Rates">"Тарифы выплат"</router-link>
              </label>
              <br/>
            </div>
          </div>
        </v-col>
      </v-row>

      <v-row class="mb-3" style="border: 1px solid gray;border-radius: 15px;">
        <v-col>
          <span style="font-size: 90%;"
          >Определите способ выплаты Агентского вознаграждения:</span
          >
          <v-radio-group
              name="agentType"
              v-model="fields.agentType"
              @change="calculateAPI"
          >
            <v-radio
                :label="`Вы Агент в форме Юр. Лицо (ИП  или ООО)`"
                :value="`JUR`"
            ></v-radio>
            <v-radio
                :label="`Вы Агент в форме Физ. Лицо`"
                :value="`FIZ`"
            ></v-radio>
          </v-radio-group>
        </v-col>
        <v-col class="caption">
          Предпочтительным и наиболее выгодным способом для клиента является
          выплата через Агента - Юридическое лицо, т.к. удорожание графика для
          клиента происходит на меньшую величину. Учитывайте это при
          формировании предварительного предложения клиенту.
        </v-col>
      </v-row>

      <v-row v-if="isFormFull()">
        <v-col class="col-md-7">
          <v-row v-if="selectedSegmentGroupsId !== 5">
            <v-col>
              <v-checkbox
                  class="mt-0"
                  v-model="fields.isSecondHand"
                  value="1"
                  :label="`Предмет лизинга был в употреблении?`"
                  name="isSecondHand"
              ></v-checkbox>
            </v-col>
            <v-col>
              <v-text-field
                  v-if="fields.isSecondHand"
                  outlined
                  dense
                  :error-messages="errors.yearOfManufacture"
                  :rules="[ruleYearofManufacture]"
                  :value="fields.yearOfManufacture"
                  :messages="
                  `Не ранее ${this.selectedSegment().limitYearManufacture} г`
                "
                  label="Год выпуска"
                  name="yearOfManufacture"
                  maxlength="4"
                  inputmode="numeric"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row
              class="d-md-none"
              style="margin-top: -15px;margin-bottom: 11px;"
          >
            <v-col cols="4">
              <div
                  class="agentValue"
                  style="display: inline-block;width: 100px;"
              >
                {{ agentPercent }}&thinsp;%
              </div>
            </v-col>
            <v-col>
              <label class="primary--text agentLabel" style="margin: 0;">
                Ставка тарифа Агентского вознаграждения зависит от группы
                выбранного клиентом имущества. Подробнее о тарифах читайте в
                разделе
                <router-link to="Rates">"Тарифы выплат"</router-link>
                .</label
              >
            </v-col>
          </v-row>

          <v-row style="margin-top: -16px;" class="d-block d-md-none">
            <v-col>
              <div class="agentSum">
                <div
                    class="agentValue"
                    style="padding: 5px 15px;vertical-align: top;"
                >
                  {{ agentFee | formatNumber }}
                  &thinsp;&#8381;
                </div>

                <div
                    class="primary--text ml-2"
                    style="font-size: 12px;vertical-align: top;width: 60%;display: inline-block;line-height: 110%;"
                >
                  Предварительный расчет суммы Агентского вознаграждения.
                </div>
                <br/>
                <label
                    class="primary--text agentLabel"
                    style="line-height: 110%;"
                >
                  Сумма рассчитывается от суммы финансирования, которое клиент
                  получит при заключении сделки. <br/><br/>
                  Точная сумма финансирования будет определена в момент
                  формирования окончательных условий сделки с Клиентом.
                  <br/><br/>
                  Подробнее о расчете суммы Агентского вознаграждения читайте в
                  разделе
                  <router-link to="Rates">"Тарифы выплат"</router-link>
                </label>
                <br/>
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="5" class="d-none d-md-flex"></v-col>
      </v-row>

      <v-row no-gutters>
        <v-col class="col-md-7">
          <template v-if="isFormFull()">
            <template>
              <v-file-input
                  :rules="rules.file"
                  label="Баланс клиента за последний отчетный квартал"
                  multiple
                  name="files[BUH_YEAR][]"
              ></v-file-input>
            </template>
            <template>
              <v-file-input
                  :rules="rules.file"
                  label="Предложение по предмету лизинга от поставщика"
                  multiple
                  required
                  name="files[OFFER][]"
              ></v-file-input>

              <v-file-input
                  label="Прочие документы"
                  messages="Прочие полезные (произвольные) файлы"
                  multiple
                  name="files[OTHER][]"
              ></v-file-input>
            </template>
          </template>
        </v-col>
        <v-col cols="5" class="d-none d-md-flex">
          <div v-if="isFormFull()">
            <div class="agentProcent ml-12">
              <div class="agentValue">{{ agentPercent }}&thinsp;%</div>
              <br/>
              <label class="primary--text agentLabel">
                Ставка тарифа Агентского вознаграждения зависит от группы
                выбранного клиентом имущества. Подробнее о тарифах читайте в
                разделе
                <router-link to="Rates">"Тарифы выплат"</router-link>
                .</label
              >
              <br/>
            </div>

            <div class="agentSum ml-12" style="margin-top: 14px;">
              <div class="agentValue">
                {{ agentFee | formatNumber }}
                &thinsp;&#8381;
              </div>
              <br/>
              <label
                  class="primary--text agentLabel"
                  style="line-height: 110%;"
              >
                Предварительный расчет суммы Агентского вознаграждения. Сумма
                рассчитывается от суммы финансирования, которое клиент получит
                при заключении сделки. <br/><br/>
                Точная сумма финансирования будет определена в момент
                формирования окончательных условий сделки с Клиентом.
                <br/><br/>
                Подробнее о расчете суммы Агентского вознаграждения читайте в
                разделе
                <router-link to="Rates">"Тарифы выплат"</router-link>
              </label>
              <br/>
            </div>
          </div>
        </v-col>
      </v-row>

      <v-row no-gutters class="mt-7">
        <v-col class="col-md-7">
          <template v-if="!isFormFull()">
            <v-btn
                color="grey"
                outlined
                type="submit"
                :class="{ pda_button: this.$vuetify.breakpoint.smAndDown }"
            >
              Отправить заявку
              <v-icon class="ml-2" size="16">fab fa-telegram-plane</v-icon>
            </v-btn>
            <div class="primary--text overline overline_small mt-2">
              Простая заявка без лишних действий!
            </div>
            <div class="text-caption mb-7 mb-md-0">
              Запрашиваемой на этой странице информации достаточно, чтобы
              получить агентское вознаграждение. Отправьте заявку и следите за
              статусом рассмотрения в разделе &laquo;Мои заявки&raquo;
            </div>
          </template>
          <template v-else>
            <div
                @click="setReducedForm"
                class="mb-3"
                style="cursor:pointer;color:grey;border-bottom: 1px dashed grey;display:inline-block"
            >
              Сокращеннная заявка
            </div>
          </template>
        </v-col>
        <v-col class="col-md-5">
          <div class="ml-md-12">
            <template v-if="isFormFull()">
              <v-btn
                  color="primary"
                  type="submit"
                  :class="{ pda_button: this.$vuetify.breakpoint.smAndDown }"
              >
                Отправить заявку
                <v-icon class="ml-2" size="16">fab fa-telegram-plane</v-icon>
              </v-btn>
            </template>
            <template class="ml-12" v-else>
              <v-btn
                  color="primary"
                  @click="setFullForm"
                  :class="{ pda_button: this.$vuetify.breakpoint.smAndDown }"
              >Продолжить заполнение +0.25%
              </v-btn>
              <div class="primary--text overline overline_small mt-2">
                Хотите заработать больше?
              </div>
              <div class="text-caption">
                Укажите дополнительные условия сделки. Загрузите предложение
                поставщика, баланс клиента за последний квартал, и вы увеличите
                свое вознаграждение на 0.25%
              </div>
            </template>
          </div>

          <a
              v-if="this.$vuetify.breakpoint.smAndDown"
              href="https://www.moneyfriends.ru/lk-agent/?utm_source=MSB#/signUp"
              target="_blank"
              border="0"
          >
            <img
                class="mt-3 mb-2"
                :src="require('@/assets/img/mf_banner_pda2.png')"
            />
          </a>
        </v-col>
      </v-row>
    </v-form>
    <v-overlay :value="overlay"></v-overlay>
  </v-container>
</template>

<script>
export default {
  name: "CreateLeasingRequest",
  props: ["edit_id"],

  data: () => ({
    valid: true,
    overlay: false,
    thankyouDialog: false,
    terms: [13, 18, 24, 30, 36, 42, 48, 54, 60],
    advances: [10, 15, 20, 25, 30, 35, 40, 45, 49],
    procentList: [15, 20, 25, 30, 35, 40, 45, 49],
    companyData: {},
    loadingCompany: false,
    companyItems: [],
    searchCompany: null,
    selectedItem: null,
    segmentGroups: [
      {
        id: 1,
        label: "Лизинг транспорта и техники: 2%",
        segments: [1, 2, 5, 6, 8, 12],
        agentPercent: 2,
        defaultAvans: 10
      },
      {
        id: 2,
        label: "Лизинг оборудования: 2%",
        segments: [3, 7, 9],
        agentPercent: 2,
        defaultAvans: 20
      },
      {
        id: 3,
        label: "ЖД-транспорт: 2%",
        segments: [4],
        agentPercent: 2,
        defaultAvans: 10
      },
      {
        id: 4,
        label: "Возвратный лизинг: 2%",
        segments: [1, 5, 8],
        agentPercent: 2,
        defaultAvans: 0
      },
      {
        id: 5,
        label: "Лизинг недвижимости: 2%",
        segments: [14, 15, 16, 17],
        agentPercent: 2,
        defaultAvans: 10
      },
      {
        id: 6,
        label: "Лизинг майнингового оборудования: 2%",
        segments: [18],
        agentPercent: 2,
        defaultAvans: 30
      },
      {
        id: 7,
        label: "Лизинг морского транспорта: 2%",
        segments: [19],
        agentPercent: 2,
        defaultAvans: 20
      }
    ],
    selectedSegmentGroupsId: 1,
    advancePercent: 0,
    term: "",
    advancePercentSlider: 0,
    TermSlider: 0,
    agentFee: 0,

    hasGuarantor: false,
    GuarantorData: {},

    loadingGuarantor: false,
    GuarantorItems: [],
    searchGuarantor: null,
    edit_inn: false,
    edit_inn_guarantor: false,
    fields: {
      type: 6,
      inn: null,
      orgName: null,
      itemName: null,
      yearOfManufacture: null,
      segmentModel: null,
      addressLegal: null,
      companyDate: null,
      fio: null,
      phone: null,
      dadata: null,
      guarantor_dadata: null,
      items: null,
      message: null,
      contacts: null,
      region: null,
      cost: null,
      taxation: null,
      isSecondHand: 0,
      files: {
        // PASSPORT1: null,
        // PASSPORT2: null,
        TYPE_BUH_YEAR: null,
        OFFER: null,
        OTHER: null
      },
      agentType: "JUR"
    },
    rules: {
      type: [v => !!v || "Обязательно для заполнения"],
      orgName: [v => !!v || "Обязательно для заполнения"],
      itemName: [v => !!v || "Обязательно для заполнения"],
      // yearOfManufacture: [
      //   v =>
      //     (v > 1999 && v <= new Date().getFullYear()) ||
      //     "Год выпуска не раньше 2000 года"
      // ],
      segmentModel: [v => !!v || "Обязательно для заполнения"],

      // addressLegal: [v => !!v || "Обязательно для заполнения"],
      // companyData: [v => !!v.value || "Обязательно для заполнения"],
      // companyDate: [v => !!v || "Обязательно для заполнения"],
      //  в дадате может не быть адреса, и тогда все ломается, юзер не может же его руками ввести  (редко, но поломалось на ИНН 261001394339)
      addressLegal: [],
      companyDate: [],
      companyData: [v => !!v || "Обязательно для заполнения"],
      fio: [v => !!v || "Обязательно для заполнения"],
      phone: [
        v => !!v || "Введите телефон",
        v => /^\d+$/.test(v) || "Телефон должен состоять только из цифр",
        v => (v && v.length === 10) || "Телефон должен содержать 10 знаков"
      ],
      inn: [
        v => !!v || "Введите ИНН",
        v => /^\d+$/.test(v) || "ИНН состоит только из цифр",
        v => (v && (v.length == 12 || v.length == 10)) || "Телефон должен содержать 10 или 12 знаков"
      ],
      dadata: [v => !!v || "Обязательно для заполнения"],
      items: [v => !!v || "Обязательно для заполнения"],
      message: [],
      contacts: [],
      region: [v => !!v || "Обязательно для заполнения"],
      // cost: [
      //   v => !!v || "Обязательно для заполнения",
      //   // https://stackoverflow.com/questions/53366501/vuetify-how-to-access-data-in-form-rule
      //   // v => { if(this.selectedSegmentGroupsId == 5) {
      //   //   console.log('===',this.selectedSegmentGroupsId);
      //   //  if (v.replace(/\s+/g, "") >= 10000000) {return true; }
      //   //  else {return "Стоимость не менее 10 млн руб.";}
      //   // } else {
      //   //   return true;
      //   // }
      //   //     },
      //   v =>
      //     v.replace(/\s+/g, "") >= 2000000 || "Стоимость не менее 2 млн руб.",
      //   v =>
      //     v.replace(/\s+/g, "") <= 70000000 || "Стоимость не более 70 млн руб."
      // ],
      taxation: [v => !!v || "Обязательно для заполнения"],
      // advancePercent: [v => !!v || "Обязательно для заполнения"],
      // term: [],
      file: [v => !!v || "Обязательно для заполнения"]
    },
    errors: {
      type: [],
      inn: [],
      orgName: [],
      itemName: [],
      yearOfManufacture: [],
      segmentModel: [],
      addressLegal: [],
      companyDate: [],
      fio: [],
      phone: [],
      dadata: [],
      items: [],
      message: [],
      contacts: [],
      region: [],
      cost: [],
      taxation: [],
      advancePercent: [],
      term: []
    },
    geoRegionList: [],
    items: [{agentPercent: 2}]
  }),
  computed: {
    costFormated: {
      set: function (val) {
        this.fields.cost = parseInt(val.replace(/\s+/g, ""));
        this.calculateAPI();
      },
      get: function () {
        if (this.fields.cost === null) {
          return "";
        }
        return (this.fields.cost + "").replace(
            /(\d)(?=(\d\d\d)+([^\d]|$))/g,
            "$1 "
        );
      }
    },

    // только текущая (выбранная в "выберите группу")
    // группа сегментов, для дропдауна "тип имущества"
    segments() {
      let group = this.segmentGroups.find(
          el => el.id === this.selectedSegmentGroupsId
      );

      return this.items.filter(el => group.segments.includes(parseInt(el.id)));
    },

    selectedSegmentGroup() {
      return this.segmentGroups.find(
          el => el.id === this.selectedSegmentGroupsId
      );
    },
    termLabel() {
      return `Срок лизинга, ${this.termMin}-${this.termMax} месяцев`;
    },
    isEmptyType: function () {
      return this.fields.type === null || !this.isEmailConfirm;
    },
    isEmailConfirm: function () {
      return 1;
      //this.$store.state.user.isEmailConfirm; // TODO обсудить подтверждение почты
    },
    isTypeLow: function () {
      return this.fields.type === 3;
    },
    isTypeMedium: function () {
      return this.fields.type === 4;
    },
    isTypeFull: function () {
      return this.fields.type === 5;
    },
    type: function () {
      return this.fields.type;
    },
    helpPhone: function () {
      return this.$store.state.user.helpPhone;
    },

    phone: {
      set: function (val) {
        this.fields.phone = val.replace(/\D+/g, "");
      },
      get: function () {
        if (this.fields.phone === null) {
          return "";
        }
        return this.fields.phone;
      }
    },
    advanceLabels: function () {
      let that = this;
      return this.advances.filter(function (number) {
        return number >= that.advanceMin;
      });
    },
    advanceMin: function () {
      return this.selectedSegment() ? this.selectedSegment().advanceMin : 10;
    },
    termMin: function () {
      return this.selectedSegment() ? this.selectedSegment().termMin : 13;
    },
    termMax: function () {
      return this.selectedSegment() ? this.selectedSegment().termMax : 60;
    },
    costMax: function () {
      return this.selectedSegment() ? this.selectedSegment().costMax : 2000000;
    },
    costMin: function () {
      return this.selectedSegment() ? this.selectedSegment().costMin : 70000000;
    },

    agentPercent: function () {
      let percent = this.selectedSegmentGroup.agentPercent;

      // при заполнении целиком - вознаграждение возрастает на 0.25%
      if (this.isFormFull()) percent += 0.25;
      return percent;
    },
    termLabels: function () {
      let that = this;
      return this.terms.filter(function (number) {
        return number >= that.termMin && number <= that.termMax;
      });
    }
  },
  watch: {
    searchCompany(val) {
      val && this.queryCompanySelections(val);
    },
    searchGuarantor(val) {
      val && this.queryGuarantorSelections(val);
    },
    companyData(suggestion) {
      if (suggestion === undefined) {
        return;
      }
      let data = suggestion.data;
      let inn = suggestion.data.inn || "";
      let address = suggestion.data.address?.unrestricted_value || "";
      let registrationDate = suggestion.data?.state?.registration_date || "";
      let formatDate = "";
      if (registrationDate !== "") {
        let date = new Date(registrationDate);
        let month = date.getMonth() + 1;
        formatDate = date.getDate() + "." + month + "." + date.getFullYear();
      }
      this.fields.orgName = suggestion.value;
      this.fields.inn = inn;
      this.fields.addressLegal = address;
      this.fields.companyDate = formatDate;
      this.fields.dadata = JSON.stringify(data);
      if (data.finance !== null && data.finance.tax_system !== null) {
        this.fields.taxation = data.finance.tax_system;
      } else {
        this.fields.taxation = null;
      }
    },
    GuarantorData(suggestion) {
      if (suggestion === undefined) {
        return;
      }
      this.fields.guarantor_dadata = JSON.stringify(suggestion.data);
    }
  },
  methods: {
    selectedSegment() {
      if (this.selectedSegmentGroupsId == 4) {
        //HACK возвратный
        return this.items.find(el => el.id == 11);
      } else if (this.selectedItem) {
        return this.items.find(el => el.id == this.selectedItem);
      } else {
        return this.segments[this.selectedSegmentGroupsId];
      }
    },
    //      findGroupBySegment(segment) {
    //   for (let i = 0; i < this.segmentGroups.length; i++) {
    //     const obj = this.segmentGroups[i];
    //     if (obj.segments.includes(segment)) {
    //       return obj;
    //     }
    //   }
    //   return null;
    // },

    changeGroup() {
      if (!this.isFormFull())
        this.advancePercent = this.selectedSegmentGroup.defaultAvans;

      // для группы ЖД сразу выбираем сегмент "ЖД вагоны" (т.к. больше в группе ничего нет)
      if (this.selectedSegmentGroupsId == 3) this.selectedItem = "4";

      this.calculateAPI();
    },

    setFullForm() {
      this.fields.type = 7;
      this.calculateAPI();
    },
    setReducedForm() {
      this.advancePercent = this.selectedSegmentGroup.defaultAvans;
      this.fields.type = 6;
      this.calculateAPI();
    },
    isFormFull() {
      if (this.fields.type === 7) return true;
      // else return false;
    },
    companyText(v) {
      const inn = v.data ? v.data.inn : "";
      return inn + ", " + v.value;
    },
    queryCompanySelections(v) {
      this.loadingCompany = true;
      let that = this;
      this.$http({
        url: "/api/dadata/party-suggest?count=5&query=" + v,
        method: "GET"
      })
          .then(res => {
            that.companyItems = res.data.suggestions;
          })
          .catch(err => {
            console.log(err);
            console.log(err.response);
          })
          .finally(() => (that.loadingCompany = false));
    },

    queryGuarantorSelections(v) {
      this.loadingGuarantor = true;
      // let that = this;
      this.$http({
        url: "/api/dadata/party-suggest?count=5&query=" + v,
        method: "GET"
      })
          .then(res => {
            this.GuarantorItems = res.data.suggestions;
          })
          .catch(err => {
            console.log(err);
            console.log(err.response);
          })
          .finally(() => (this.loadingGuarantor = false));
    },

    validate(e) {
      if (this.$refs.form.validate()) {
        let formData = new FormData(e.target);

        // HACK захардкодил возвратный лизинг, чтобы не создавать дубли в админке
        if (this.selectedSegmentGroupsId == 4) {
          formData.set("items", 11);
        }
        // если срок пустой - подставляем 15 месяцев, т.к. вознаграждение расчитывается таким же образом.
        if (!this.term) {
          formData.set("term", 15);
        }

        if (this.edit_id && this.companyData?.data) {
          formData.set("dadata", JSON.stringify(this.companyData.data));
        }
        if (this.edit_id && this.GuarantorData?.data) {
          formData.set("guarantor_dadata", JSON.stringify(this.GuarantorData.data));
        }

        // если аванс пустой - подставляем по умолчанию для данного сегмента.
        if (!this.advancePercent) {
          formData.set(
              "advancePercent",
              this.selectedSegmentGroup.defaultAvans
          );
        }

        let that = this;
        that.overlay = true;
        this.resetErrors();

        const url = this.edit_id
            ? "/agents/leasing/create?id=" + this.edit_id
            : "/agents/leasing/create";

        this.$http
            .post(url, formData, {
              headers: {
                "Content-Type": "multipart/form-data"
              }
            })
            .then(function () {
              that.$router.push({name: "Dossier"});
              // that.overlay = false;
              // that.thankyouDialog = true;
            })
            .catch(function (err) {
              console.log(err);
              that.addErrors(err.response.data);
            })
            .finally(function () {
              that.overlay = false;
            });
      } else {
        console.error("form is INVALID!");
      }
      setTimeout(() => {
        const firstErrors = document.getElementsByClassName("error--text");
        console.log(firstErrors);

        for (let item of firstErrors) {
          if (
              !item.classList.contains("d-none") &&
              item.classList.contains("v-input")
          ) {
            console.log(item);
            let input = item.querySelectorAll("input");
            console.log(input);
            input[0].focus();
            input[0].scrollIntoView(false);
            break;
          }
        }
      }, 1000);
    },
    reset() {
      this.$refs.form.reset();
      this.fields = {
        type: null,
        inn: null,
        orgName: null,
        addressLegal: null,
        companyDate: null,
        fio: null,
        phone: null,
        dadata: null,
        items: null,
        message: null,
        region: null,
        cost: null,
        taxation: null,
        advancePercent: null,
        term: null,
        files: {
          PASSPORT1: null,
          PASSPORT2: null,
          TYPE_BUH_YEAR: null,
          OFFER: null
        }
      };
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    addErrors: function (errors) {
      errors.forEach(item => {
        this.errors[item.field].push(item.message);
      });
    },
    resetErrors() {
      Object.keys(this.errors).forEach(key => {
        this.errors[key] = [];
      });
    },
    getRegions: function () {
      let that = this;
      that.$http
          .get("/agents/leasing/regions")
          .then(function (res) {
            that.geoRegionList = res.data;
          })
          .catch(function (err) {
            console.log(err);
          });
    },
    getItems: function () {
      let that = this;
      that.$http
          .get("/agents/leasing/items")
          .then(function (res) {
            that.items = res.data;
          })
          .catch(function (err) {
            console.log(err);
          });
    },
    calculateAPI: function () {
      // для сокращенной формы берем стандартные размеры аванса,
      // для каждой группы сегментов они прописаны
      let advancePercent = 15;
      if (this.advancePercent > 0) {
        advancePercent = this.advancePercent;
      }

      const term = this.term == "" ? 15 : this.term;

      const data = {
        itemCost: parseInt(this.fields.cost),
        advancePayment: (this.fields.cost * advancePercent) / 100,
        term: term,
        agentType: this.fields.agentType,
        segmentId: parseInt(this.selectedItem),
        agentPercent: this.agentPercent
      };

      this.$http
          .post("/api/open-calculator/calc", data)
          .then(response => {
            this.agentFee = response.data.agentFee;
          })
          .catch(function (error) {
            console.log(error);
          });
    },

    ruleCost(v) {
      v = v.replace(/\s+/g, "");
      // верхний лимит
      // if (v > 70000000) return "Стоимость не более 70 млн руб.";
      if (v > this.costMax)
        return `Стоимость не более ${
            this.selectedSegment().costMaxFormated
        } руб.`;
      // нижние лимиты -

      if (v >= this.costMin) {
        return true;
      } else {
        return `Стоимость не менее ${
            this.selectedSegment().costMinFormated
        } руб.`;
      }
    },

    ruleDadataCompany() {
      if (!this.companyData.value) {
        return "Обязательное поле";
      }
      return true;
    },

    ruleTerm(v) {
      this.errors.term = null;
      if (!v) return true;

      if (v > this.termMax)
        this.errors.term = "Срок не более " + this.termMax + " мес";
      if (v < this.termMin)
        this.errors.term = "Срок не менее " + this.termMin + " мес";

      return this.errors.term;
    },
    ruleYearofManufacture(v) {
      this.errors.yearOfManufacture = null;
      if (!v) return true;

      let minYear = this.selectedSegment().limitYearManufacture;
      if (v < minYear)
        this.errors.yearOfManufacture = `Год выпуска не ранее ${minYear} г.`;

      return this.errors.yearOfManufacture;
    }
  },
  filters: {
    formatNumber: function (value) {
      if (!value) return 0;
      value = value.toString();
      return value.replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, "$1 ");
    }
  },
  mounted() {
    setTimeout(() => {
      // если уже в профиле у агента прописан его тип, то сразу ставим.
      // пока такой костыль через таймоаут, а то из стора не всегда успевет получить данные юзера
      if (this.$store.state.user.type == 1) {
        this.fields.agentType = "FIZ";
      }
    }, 1500);
  },
  created() {
    this.getRegions();
    this.getItems();

    if (this.edit_id) {
      this.$http({
        url: "/agents/leasing/get?id=" + this.edit_id
        // method: "GET"
      })
          .then(res => {
            this.fields = res.data;
            this.fields.isSecondHand = res.data.isSecondHand.toString(); // почему-то чекбокс не срабатывал на число
            this.hasGuarantor = res.data.guarantor_dadata ?? 0;

            this.GuarantorData["data"] = res.data.guarantor_dadata;
            this.companyData["data"] = res.data.dadata;

            this.advancePercent = res.data.advancePercent;
            this.term = res.data.term;
            const segment_id = parseInt(res.data.items);

            const segment_group = this.segmentGroups.find(obj =>
                obj.segments.includes(segment_id)
            );
            this.selectedSegmentGroupsId = segment_group.id;
            this.selectedItem = segment_id;
            this.calculateAPI();
          })
          .catch(err => {
            console.log(err);
            console.log(err.response);
          });
      // .finally(() => (this.loadingGuarantor = false))
    }
  }
};
</script>

<style lang="scss">
.edit_inn {
  font-size: 85%;
  font-weight: 600;
}

.breadcrumbs {
  padding: 0 !important;
  padding-bottom: 10px !important;
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 10px;
}

::-moz-placeholder,
::-webkit-input-placeholder {
  font-size: 85%;
  line-height: 120%;
}

.overline_small.overline_small.overline_small {
  font-size: 70% !important;
  line-height: 130%;
}

.pda_button {
  width: 100%;
}
</style>
