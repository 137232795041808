<template>
  <v-container>
    <h2>Мои заявки</h2>
    <!-- <PrescoringPopup> -->
    <v-dialog v-model="showPrescoringPopup" width="500">
      <v-card>
        <v-card-title class="text-h6 grey lighten-4">
          {{ lastRequestOrgName }}
        </v-card-title>

        <v-card-text class="py-5 px-5">
          <div v-if="lastRequestPrescoringPassed" class="text-center">
            <img
              width="80"
              class="mx-auto mb-5"
              :src="require('@/assets/img/prescoring_success.gif')"
            />
            <br />
            <b>
              Результаты предварительной проверки положительные.
              <br />Стоп-факторы не найдены.
            </b>
            <br /><br />
            Ваша заявка поступила на рассмотрение. <br />Следите за статусом
            заявки.
          </div>

          <div class="text-center" v-else-if="lastRequestPrescoringFailed">
            <img
              width="80"
              class="mx-auto"
              :src="require('@/assets/img/prescoring_fail.gif')"
            />
            <div v-html="lastRequestFailedMsg"></div>
          </div>

          <div v-else class="text-center">
            <img :src="require('@/assets/img/prescoring_preloader.svg')" />

            <div class="mt-5 mb-1" style="height:50px">
              <transition name="slide-fade">
                <p v-if="prescoringTitleShow">
                  {{ prescoringTitle }}
                </p>
              </transition>
            </div>
            <v-progress-linear
              indeterminate
              color="grey lighten-4"
              class="mb-0"
            ></v-progress-linear>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <span style="color:lightgrey" class="ml-3">ПРЕСКОРИНГ</span>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="showPrescoringPopup = false">
            Свернуть
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- </PrescoringPopup> -->

    <v-breadcrumbs
      class="breadcrumbs"
      :items="[
        {
          text: 'Главная',
          disabled: false,
          href: '/'
        },
        {
          text: 'Профиль',
          disabled: false,
          href: '/Profile'
        },
        {
          text: 'Мои заявки',
          disabled: true
        }
      ]"
    ></v-breadcrumbs>

    <v-row class="">
      <v-col>
        <!--newComments={{newComments}}-->
        <!--        {{opened_items}}-->
        <div v-if="!isEmailConfirm" class="caption">
          Для получения уведомлений об изменении статуса заявок, пожалуйста
          укажите свой e-mail в
          <router-link :to="'Profile'">профиле</router-link>.
        </div>
        <div style="position: relative">
          <div v-if="!scrolled" class="d-sm-none scroll_suggest">
            &#10140;<br />&#10140;
          </div>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    Дата
                  </th>
                  <th class="text-left">
                    №
                  </th>
                  <th class="text-left">
                    Лизингополучатель
                  </th>
                  <th class="text-left">
                    Тариф
                  </th>
                  <th class="text-right">
                    Сумма финансирования
                  </th>

                  <th class="text-right" style="width: 120px;">
                    Расчетная агентская комиссия
                  </th>
                  <th class="text-right">
                    Статус
                  </th>
                </tr>
              </thead>
              <tbody>
                <template v-for="item in items">
                  <tr
                    v-bind:key="item.id"
                    @click="toggle(item)"
                    :class="{
                      bgGrey: item.showActions,
                      calloff: isCalledOff(item)
                    }"
                  >
                    <td style="width: 100px;">{{ item.createDate }}</td>
                    <td style="width: 60px;">{{ item.id }}</td>
                    <td
                      style="width: 230px;"
                      :class="{ hasNewMsg: item.hasNewMsg > 0 }"
                    >
                      {{ item.orgName }}
                    </td>
                    <td>{{ item.agentPercent }}%</td>

                    <td class="text-right">{{ item.costFormat }}</td>
                    <td class="text-right">{{ item.agentSum }}</td>
                    <td
                      class="text-right pr-6 status_cell"
                      :class="{
                        hasNewState: item.hasNewState > 0,
                        showActions: item.showActions,
                        isCalledOff: isCalledOff(item),
                        isDeclined: isDeclined(item),
                        isAccepted: isAccepted(item)
                      }"
                    >
                      {{ item.stateAgent }}
                    </td>
                  </tr>
                  <tr v-bind:key="999 + item.id" v-if="item.showActions">
                    <td colspan="8" class="showActions bgGrey py-3">
                      <div v-if="item.agentFailedMsg" class="text-left">
                        <div v-html="item.agentFailedMsg"></div>
                        <v-icon color="red" x-small>fas fa-times</v-icon>
                      </div>

                      <table style="width: 100%;">
                        <tr>
                          <td style="width: 390px;" class="text-left">
                            <i>{{ item.segment.name }}</i> <br />
                            <span class="params">ИНН:</span> {{ item.inn }}
                            <br />

                            <template v-if="item.itemName">
                              <span class="params">Предмет:</span>
                              {{ item.itemName }}
                              <span v-if="item.isSecondHand"
                                >(б/у {{ item.yearOfManufacture }}г)</span
                              >
                              <br
                            /></template>

                            <span class="params">Контакт:</span> {{ item.fio }}
                            <br />
                            <span class="params">Тел:</span> {{ item.phone }}
                            <br />
                            {{ item.message }}
                          </td>

                          <td style="padding-left: 11px;" class="text-left">
                            <template v-if="item.type == 7 || item.type == 9">
                              <span>Полная заявка</span><br />
                              <span class="params">Срок:</span>
                              {{ item.term }} мес. <br />
                              <span class="params">Аванс:</span>
                              {{ item.advancePercent }}% <br />
                              <template v-if="item.requestFiles.length > 0">
                                <div
                                  v-for="file in item.requestFiles"
                                  :key="file.id"
                                >
                                  <a :href="createUrl(file)">{{
                                    createName(file.type)
                                  }}</a>
                                  <br />
                                </div>
                              </template>
                            </template>
                            <span v-if="item.type == 6"
                              >cокращенная заявка</span
                            >
                          </td>

                          <td style="padding-right: 11px;" class="text-right">
                            <v-btn
                              small
                              depressed
                              color="info"
                              @click="ClickCallOff(item)"
                            >
                              <v-icon class="mr-2" size="12"
                                >fas fa-trash</v-icon
                              >
                              Отозвать
                            </v-btn>

                            <div class="mt-3">
                              <v-btn
                                v-if="isProAgent"
                                small
                                color="info"
                                outlined
                                :to="'/edit-pro/' + item.id"
                              >
                                <v-icon class="mr-2" size="12"
                                  >fas fa-edit
                                </v-icon>
                                Редактировать
                              </v-btn>
                              <v-btn
                                v-else
                                small
                                color="info"
                                outlined
                                :to="'/edit/' + item.id"
                              >
                                <v-icon class="mr-2" size="12"
                                  >fas fa-edit
                                </v-icon>
                                Редактировать
                              </v-btn>
                            </div>
                          </td>
                        </tr>
                      </table>

                      <template v-if="item.comments.length > 0">
                        <div class="text-left">
                          <div
                            class="mb-2 mt-2"
                            style="font-weight: 600;font-size: 100%;line-height: 110%;"
                          >
                            Комментарий менеджера &laquo;МСБ-Лизинг&raquo;
                            <br />
                            <span style=" "
                              >{{ item.agentName }} {{ item.agentPhone }}</span
                            >
                          </div>
                          <div
                            class="row"
                            :key="comment.id"
                            v-for="comment in item.comments"
                          >
                            <div class="col col-1" style="font-size: 80%;">
                              {{ comment.created }}
                            </div>
                            <div class="col">
                              <span v-html="comment.msg"></span>
                              <span v-html="comment.stateChangeMsg"></span>
                            </div>
                          </div>
                        </div>
                      </template>
                    </td>
                  </tr>
                </template>
              </tbody>
            </template>
          </v-simple-table>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Axios from "axios";

export default {
  name: "Dossier",
  props: ["newComments"],
  data: () => ({
    PRESCORING_ANIMATION_TIMEOUT_SEC: 60,
    flag: false,
    calloff_confirm_popup: false,

    showPrescoringPopup: false,
    // showPrescoringPopup: true,
    lastRequestOrgName: "",
    lastRequestPrescoringPassed: false,
    lastRequestPrescoringFailed: false,
    prescoringTitle: "Срок регистрации",
    prescoringTitleShow: true, // нужно только для vuejs transition анимации
    prescoringTitles: [
      "Ликвидация / Реогранизация",
      "Банкрот",
      "Выручка за последний год",
      "Реестр недобросовестных поставщиков",
      "Руководители экстремисты",
      "Руководитель банкрот менее 3 лет назад",
      "Чистая прибыль",
      "Собственный капитал",
      "Задолженность по ФССП",
      "Приостановки по р/сч",
      "Задолженность по налогам и сборам",
      "Стоимость ПЛ / Валюта баланса"
    ],

    // prescoringAnimationTimeLimit: 0,
    items: [],
    scrolled: false,
    // список открытых юзером заявок. нужен чтобы при повторном получении списка их открыть автоматом
    opened_items: []
  }),
  computed: {
    isEmailConfirm: function() {
      return this.$store.state.user.isEmailConfirm;
    },
    isProAgent: function() {
      return this.$store.state.user.is_pro;
    }
  },
  methods: {
    getItems: function() {
      this.$http
        .get("/agents/leasing/list")
        .then(resp => {
          this.items = resp.data;

          // при повторном получении сохраняем открытыми те заявки, которые уже были открыты агентом
          this.items.forEach(el => {
            if (this.opened_items.includes(el.id))
              // el['showActions'] = true
              this.$set(el, "showActions", true);
          });

          // если последняя заявка в стадии прескоринга - показываем попап-анимацию
          this.checkLastRequest();
          this.animatePrescoringTitle();

          //   максимальное время показа прескоринг-анимации (модальное окно).
          //   после PRESCORING_ANIMATION_TIMEOUT_SEC сек прячем при любом исходе
          // (перестраховка от глюков бекенда, зависание очереди, проблемы с поставщиком данных и т.д).
          // чтобы агент не думал что "все пропало, все зависло".
          setTimeout(() => {
            this.showPrescoringPopup = false;
            this.getItems(); // на всякий случай переполучаем список
            // (иногда пинговалка has-new-comments перестававала работать)
          }, this.PRESCORING_ANIMATION_TIMEOUT_SEC * 1000);
        })
        .catch(err => {
          console.log(err);
          console.log(err.response);
        });
    },

    toggle(item) {
      if (!this.isCalledOff(item)) {
        if (item.showActions == true) {
          // закрываем
          this.$set(item, "showActions", false);
          this.opened_items = this.opened_items.filter(el => el !== item.id);
        } else {
          //открываем
          this.$set(item, "showActions", true);
          this.opened_items.push(item.id);
          this.ajaxCommentOpened(item.id);
        }
      }
    },
    isCalledOff(item) {
      if (item.state == 6) return true; // OnlineRequest::STATE_CALLOFF;
    },

    isDeclined(item) {
      if (
        item.state == 4 ||
        item.state == 9 ||
        item.state == 10 ||
        item.state == 11 ||
        item.state == 13
      )
        return true;
    },

    isAccepted(item) {
      if (item.state == 3 || item.state == 1 || item.state == 7) return true;
      // OnlineRequest::STATE_ACCEPT or STATE_NEW or  STATE_CONTRACTED = 7;
    },

    ajaxCommentOpened(id) {
      this.$http.post("/agents/leasing/comment-opened?id=" + id).catch(err => {
        console.log(err);
        console.log(err.response);
      });
    },
    // кликнули "отозвать" заявку
    ClickCallOff(item) {
      if (
        window.confirm("Действительно ОТОЗВАТЬ заявку '" + item.orgName + "'?")
      ) {
        Axios({
          url: process.env.VUE_APP_API_URL + "/agents/leasing/actions",
          data: {
            request_id: item.id,
            action: "SET_STATE_CALLOFF"
          },
          method: "POST"
        })
          .then(resp => {
            console.log(resp);
            this.getItems();
          })
          .catch(err => {
            console.log(err);
          });
      }
    },
    createUrl(item) {
      return (
        this.$http.defaults.baseURL +
        "/online_request/file/download?hash=" +
        item.hash
      );
    },
    createName(type) {
      if (type == "BUH_YEAR") return "Баланс";
      if (type == "OTHER") return "Прочее";
      if (type == "OFFER") return "Предложение";
    },

    checkLastRequest() {
      this.lastRequestOrgName = this.items[0].orgName;
      this.lastRequestState = this.items[0].state;
      this.lastRequestFailedMsg = this.items[0].agentFailedMsg;

      // есть новая (обычно только добавленная) заявка, показываем окно анимашку прескоринга
      if (this.lastRequestState === 0) {
        // STATE_BEFORE_PRESCORING

        // только если заявка только что добавлена, буквально несколько минуту (PRESCORING_ANIMATION_TIMEOUT_SEC) назад,
        // тогда только показываем  попап с анимашкой
        // а так это защита от косяков - допустим бекенд не отработал (упала очередь),
        // заявка висит в статусе прескоринга уже полдня,
        // а у агента анимация появляется каждый раз, когда он заходит в "мои заявки".
        var created_diff_sec = Date.now() / 1000 - this.items[0].updatedAt;
        if (created_diff_sec < this.PRESCORING_ANIMATION_TIMEOUT_SEC) {
          this.showPrescoringPopup = true;
        }
      } else if (this.lastRequestState === 1) {
        // STATE_NEW
        // бекенд отработал, прескоринг одобрен (показываем зеленую галочку)
        this.lastRequestPrescoringPassed = true;
      } else if (
        this.lastRequestState === 9 ||
        this.lastRequestState === 10 ||
        this.lastRequestState === 11 ||
        this.lastRequestState === 12 ||
        this.lastRequestState === 13
      ) {
        // STATE_PRESCORING_* проблемные статусы прескоринга (показываем иконку и описываем проблему)
        this.lastRequestPrescoringFailed = true;
      }
    },

    animatePrescoringTitle() {
      setInterval(() => {
        this.prescoringTitleShow = true;

        // случайно выбираем для анимационного попапа текущую проверку.
        this.prescoringTitle = this.prescoringTitles[
          Math.floor(Math.random() * this.prescoringTitles.length)
        ];

        //   следующим "шагом" проверки
        setTimeout(() => (this.prescoringTitleShow = false), 2000);
      }, 3000);
    }
  },
  mounted() {
    this.getItems();

    // на мобильном есть иконки-подсказки, и после скролла юзером мы их прячем
    let table = document.getElementsByClassName("v-data-table__wrapper")[0];
    table.addEventListener("scroll", () => (this.scrolled = true));

    // продублировал вызов комментов. из  NaBarLanding почему-то он не всегда вызывался
    setInterval(() => {
      this.$store.dispatch("getNewCommentsCount").catch(err => {
        console.log(err);
      });
    }, 10000);
  },

  watch: {
    newComments() {
      // если пропс изменился, то значит есть новые комментарии,
      // получаем заново список заявок
      this.getItems();
    }
  }
};
</script>

<style scoped>
tr {
  cursor: pointer;
}

th {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 60% !important;
}

td {
  font-size: 80% !important;
}

.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:hover:not(.showActions) {
  background: #e1efe2 !important;
}

.bgGrey {
  background: #f7f7f7 !important;
}
.status_cell {
  position: relative;
}
.status_cell:not(.isCalledOff)::after {
  content: "▾";
  position: absolute;
  top: 12px;
  right: 7px;
  font-size: 120%;
  transition: all 0.2s;
  color: grey;
}
.showActions::after {
  /*content: '▴'!important;*/
  transform: rotate(180deg);
  top: 15px !important;
  color: black !important;
}

.isDeclined {
  color: red;
}
.isAccepted {
  color: green;
}

tr.bgGrey td {
  border-bottom: none !important;
}

.showActions {
  cursor: initial !important;
  text-align: right;
}

tr.calloff {
  color: grey;
  cursor: initial !important;
}
.params {
  text-transform: uppercase;
  font-size: 90%;
  font-weight: bold;
}

.hasNewMsg,
.hasNewState {
  font-weight: bold;
  position: relative;
  transiton: 0.5s all;
}
.hasNewMsg:after {
  position: absolute;
  content: "new";
  color: white;
  background-color: #0e69df;
  padding: 0 4px;
  top: 5px;
  font-size: 70%;
  border-radius: 3px;
  display: inline-block;
}

.hasNewState {
  background-color: #f6f6f6;
}

.slide-fade-enter-active {
  transition: all 0.4s ease;
}
.slide-fade-leave-active {
  transition: all 0.7s ease; /* cubic-bezier(1, 0.5, 0.8, 1); */
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for <2.1.8 */ {
  transform: translateY(15px);
  opacity: 0;
}
</style>
