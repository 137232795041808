<template>
  <div>
    <!-- preload slider img --->
    <link as="image" href="/landing/slider0.jpg" rel="preload" />
    <link as="image" href="/landing/slider1.jpg" rel="preload" />
    <link as="image" href="/landing/slider2.jpg" rel="preload" />
    <link as="image" href="/landing/slider3.jpg" rel="preload" />

    <v-row class="height100" no-gutters style="border-top: 1px solid #EEEEEE;">
      <v-col md="6">
        <div class="row no-gutters height100">
          <v-spacer></v-spacer>
          <div
            class="col_center col-auto d-flex flex-column "
            style="position: relative;"
          >
            <div class="px-5">
              <h1 class="welcome__title">
                Агентский кабинет
                <nobr>&laquo;МСБ-Лизинг&raquo;</nobr>
              </h1>
              <div class="col1_wrap pb-5 mb-5">
                <div class="mb-md-5 mb-2 row">
                  <div class="col">
                    <img
                      alt="ico_car"
                      class="icon my-3"
                      src="/landing/ico_car.png"
                    />

                    <div class="subtitle mb-3">
                      Передайте лизинговую заявку через кабинет
                    </div>

                    <div class="info_text d-none d-md-block">
                      — моментальная регистрация <br />
                      — простая форма подачи заявки <br />
                      — предварительный расчет <br />
                      — статусы прохождения заявки
                    </div>
                  </div>
                  <div class="col">
                    <img
                      alt="ico_car"
                      class="icon my-3"
                      src="/landing/ico_money.png"
                    />

                    <div class="subtitle mb-3">
                      Получите агентскую комиссию до 2.25%
                    </div>
                    <div class="info_text d-none d-md-block">
                      Возможен любой статус агента: юридическое, физическое лицо
                      или индивидуальный предприниматель
                    </div>
                  </div>
                </div>

                <div class="d-inline-flex flex-column links-wrapper">
                  <HomeLink
                    href="/login"
                    text="Хочу отправить заявку"
                    title="войти в кабинет"
                    tooltip="<b>В кабинете можно:</b>
          <ul>
          <li>создать и направить заявку на рассмотрение,
          <li>автоматически проверить вероятность одобрения заявки,
          <li>рассчитать свою агентскую комиссию,
          <li>следить за статусами рассмотрения заявки,
          <li>создать и выгрузить предварительный расчет для клиента в PDF.
          </ul>"
                  />
                  <HomeLink
                    href="/about"
                    text="Хочу узнать как зарабатывать"
                    title="О сервисе"
                    tooltip="<b>В разделе «О сервисе» вы узнаете:</b>
          <ul>
          <li>сколько здесь можно заработать,
          <li>кто может стать Агентом,
          <li>как стать Агентом,
          <li>порядок шагов для получения вознаграждения,
          <li>порядок и срок прохождения сделок,
          <li>какие клиенты и сделки могут быть реализованы с «МСБ-Лизинг».
          </ul>"
                  />
                  <HomeLink
                    href="/sign-up"
                    text="Хочу стать агентом «МСБ-Лизинг»"
                    title="зарегистрироваться"
                    tooltip="<b>Регистрация займет 1 минуту!</b> <br>
Потребуется ввести имя, телефон и подтвердить его смс.  <br>
 Личные данные понадобятся только в момент реализации первой сделки,
  для заключения агентского договора"
                  />
                </div>
              </div>
            </div>
            <div></div>
            <div class="footer d-none d-md-block ">
              © ООО «МСБ-Лизинг» {{ new Date().getFullYear() }}
              <span class="ml-4 mr-4">|</span>
              <a
                href="https://t.me/agent_msb"
                style="text-decoration: none; color: rgba(0, 0, 0, 0.87);"
                target="_blank"
              >
                <img
                  class="mr-1"
                  src="/landing/ico_telegram.png"
                  style="vertical-align: middle;height: 20px;"
                />
                телеграм-канал для агентов</a
              >
            </div>
          </div>
        </div>
      </v-col>
      <v-col md="6">
        <!--          cycle      interval="300000"-->
        <v-carousel
          :show-arrows="false"
          height="100%"
          hide-delimiter-background
          light
        >
          <!--          <v-carousel-item-->
          <!--              class="slide_homepage slideA"-->
          <!--          >-->
          <!--            <a href="/create-leasing"   v-if="this.$vuetify.breakpoint.smAndDown">-->
          <!--            <div class="slide_wrap" style="display: flex;flex-direction: column-reverse;">-->
          <!--            </div>-->
          <!--            </a>-->

          <!--            <div v-else class="slide_wrap" style="display: flex;flex-direction: column-reverse;">-->
          <!--              <a href="/create-leasing"  v-if="this.$vuetify.breakpoint.lgAndUp" class="slide_btn konkurs_btn">Дерзайте</a>-->
          <!--            </div>-->

          <!--          </v-carousel-item>-->

          <v-carousel-item
            v-for="(slide, i) in slides"
            :key="i"
            :class="'slide_homepage slide' + i"
          >
            <div class="slide_wrap">
              <div class="slide_title">
                {{ slide.title }}
              </div>
              <div class="slide_txt">{{ slide.txt }}</div>
              <a :href="slide.href" class="slide_btn">{{ slide.btn }}</a>
            </div>
          </v-carousel-item>
        </v-carousel>
      </v-col>
      <div class="footer py-4 d-block d-md-none">
        © ООО «МСБ-Лизинг» {{ new Date().getFullYear() }}
      </div>
    </v-row>
  </div>
</template>

<script>
import HomeLink from "@/components/HomeLink";

export default {
  name: "Home",
  components: { HomeLink },
  data: function() {
    return {
      slides: [
        {
          title: "Видео-обзор агентского кабинета за 3 минуты",
          txt:
            "Как рассчитать график, отправить заявку, подписать агентский договор, где отслеживать агентские выплаты и другое",
          href: "https://www.youtube.com/watch?v=G0rn-72Xjdk",
          btn: "перейти к видео"
        },
        {
          title: "Лизинг железнодорожного транспорта",
          txt:
            "Вагоны, полувагоны, платформы, крытые вагоны, хопперы, цистерны, думпкары.",
          href:
            "https://msb-leasing.ru/services/lizing-zheleznodorozhnogo-transporta/",
          btn: "Подробнее"
        },
        {
          title: "Лизинг спецтехники",
          txt: "Новая и Б/у техника. Срок выплат до 60 месяцев.",
          href: "https://msb-leasing.ru/services/lizing-spetstekhniki/",
          btn: "Подробнее"
        },
        {
          title: "Лизинг грузового транспорта",
          txt: "Новая и Б/у техника. Срок выплат до 60 месяцев.",
          href: "https://msb-leasing.ru/services/lizing-gruzovykh-avtomobiley",
          btn: "Подробнее"
        },
        {
          title: "Лизинг медицинского оборудования",
          txt:
            "Томографы, УЗИ-аппараты, эндоскопы, стоматологическое оборудование.",
          href:
            "https://msb-leasing.ru/services/lizing-meditsinskogo-oborudovaniya",
          btn: "Подробнее"
        }
      ]
    };
  },
  computed: {
    isLoggedIn: function() {
      return this.$store.getters.isLoggedIn;
    }
  }
};
</script>
<style lang="scss">
//<style lang="scss" scoped>
.welcome__title {
  font-size: 24px;
  line-height: 35px;
  font-weight: 800;
  color: #333333;

  @media (min-width: 800px) {
    font-size: 32px;
    line-height: 55px;
  }

  @media (min-height: 800px) {
    margin-top: 1em;
    margin-bottom: 1em;
  }
}
.konkurs_btn {
  display: none !important;
  @media (min-height: 800px) {
    display: block !important;
  }
}

.v-carousel__item {
  align-items: flex-end;

  @media (max-width: 1000px) {
    align-items: initial;
  }
}
.slide_title {
  font-size: 36px;
  line-height: 55px;
  letter-spacing: 0.02em;
  color: #ffffff;
  font-weight: 600;
  @media (max-width: 800px) {
    font-size: 28px;
    line-height: 130%;
  }
}

.slide_txt {
  font-weight: normal;
  font-size: 15px;
  line-height: 25px;
  letter-spacing: 0.02em;
  color: #ffffff;
  margin-top: 30px;
  margin-bottom: 70px;

  @media (max-width: 800px) {
    font-size: 14px;
  }
}

.slide_btn {
  display: block;
  width: 180px;
  padding: 16px;

  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 5px;
  font-weight: 600;
  font-size: 10px;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  text-decoration: none;
  transition: all 0.3s;
  background: rgba(255, 255, 255, 0.1);
  //backdrop-filter: blur(5px);
}

.slide_btn:link,
.slide_btn:visited {
  color: white;
}

.slide_btn:hover {
  background: rgba(255, 255, 255, 0.25);
}

.slide_wrap {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 50px;
  padding-bottom: 80px;

  max-width: 660px;

  height: 500px;

  @media (min-width: 1000px) {
    margin-top: 80px;
    margin-left: 100px;
  }

  @media (min-width: 1900px) {
    margin-top: 220px;
    margin-left: 210px;
  }
}

.slide_homepage {
  background-size: cover;
  background-position-x: -360px;
}

.slideA {
  //background-image: url("/konkurs/slider1.jpg");
  background-position-x: 0;
  background-image: url("/konkurs/slide_watch.jpg");
  background-size: cover;

  @media (min-width: 710px) {
    background-image: url("/konkurs/slide.jpg");
  }

  @media (min-width: 1020px) {
    background-image: url("/konkurs/slide.jpg");
  }
}

.slide0 {
  background-image: url("/landing/slider00.jpg");
  background-position-x: 0;
}

.slide1 {
  background-image: url("/landing/slider0.jpg");
}

.slide2 {
  background-image: url("/landing/slider1.jpg");
}

.slide3 {
  background-image: url("/landing/slider211.jpg");
  background-position-x: 0;

  @media (max-width: 380px) {
    background-position-x: -126px;
  }
}

.slide4 {
  background-image: url("/landing/slider3.jpg");
}

.footer {
  bottom: 20px;
  //left: 20px;
  left: 40px; // типа отцентрировали
  position: absolute;
  font-size: 10px;
  line-height: 15px;
  letter-spacing: 0.1em;
  text-transform: uppercase;

  @media (max-width: 1000px) {
    position: static;
    text-align: center;
    width: 100%;
  }
}

.col1_wrap {
  max-width: 457px;
}

.col_center {
  justify-content: center;
  width: 720px;
}

.icon {
  height: 57px;
  @media (min-width: 1000px) {
    height: 64px;
  }
}

.info_text {
  font-size: 12px;
  line-height: 20px;
}

.subtitle {
  font-style: normal;
  font-weight: 300;
  color: #040404;
  font-size: 11px;
  line-height: 14px;

  @media (min-width: 1000px) {
    font-size: 18px;
    line-height: 20px;
  }
}

.links-wrapper {
  width: 100%;
  margin-top: 0px;
  @media (min-height: 800px) {
    margin-top: 50px;
  }
}

.height100 {
  @media (min-width: 1000px) {
    height: calc(100vh - 85px);
  }
}
</style>
