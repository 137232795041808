<template>
  <v-container>
    <h2>Обратная связь</h2>
    <v-breadcrumbs
      :items="[
        {
          text: 'Главная',
          disabled: false,
          href: '/'
        },
        {
          text: 'Обратная связь',
          disabled: true
        }
      ]"
      class="breadcrumbs"
    ></v-breadcrumbs>
    <v-form
      v-if="!formSubmit"
      ref="form"
      v-model="valid"
      class="mt-5"
      lazy-validation
      @submit.prevent="sendForm"
    >
      <div class="mb-4">
        Оставьте обратную связь, мы обязательно учтем ваши комментарии и
        пожелания.
        <br />Если вопрос требует дополнительных уточнений - мы с вами свяжемся.
      </div>
      <v-text-field
        outlined
        dense
        v-model="name"
        :error-messages="errors.name"
        :rules="nameRules"
        label="Имя"
        required
      ></v-text-field>

      <v-text-field
        outlined
        dense
        :rules="phoneRules"
        v-model="phone"
        :error-messages="errors.phone"
        counter
        label="Телефон"
        maxlength="10"
        prefix="+7"
        required
        type="tel"
      ></v-text-field>

      <v-textarea
        outlined
        dense
        v-model="text"
        :error-messages="errors.text"
        label="Комментарий"
        name="msg"
        rows="3"
      ></v-textarea>
      <v-btn :disabled="!valid" class="mr-4 mt-1" color="primary" type="submit">
        <v-icon class="mr-2" size="16">fas fa-paper-plane</v-icon>
        Отправить
      </v-btn>
    </v-form>
    <template v-else>
      <h3>Спасибо!</h3>
      Ваши комментарии помогают нам сделать сервис лучше!
    </template>
  </v-container>
</template>

<script>
export default {
  name: "Feedback",
  data: () => ({
    text: "",
    name: "",
    phone: "",
    formSubmit: false,
    valid: true,
    checkbox: false,
    errors: {
      text: [],
      name: [],
      phone: []
    },
    nameRules: [v => !!v || "Введите имя"],
    phoneRules: [
      v => !!v || "Введите телефон",
      v => /^\d+$/.test(v) || "Телефон должен состоять только из цифр",
      v => (v && v.length === 10) || "Телефон должен содержать 10 знаков"
    ]
  }),
  created() {
    this.name = this.$store.getters.user.name ?? "";
    this.phone = this.$store.getters.user.phone ?? "";
  },
  // computed: {
  //   name: function() {
  //     return this.$store.getters.user.name;
  //   },
  //   phone: function() {
  //     return this.$store.getters.user.phone;
  //   }
  // },
  methods: {
    sendForm() {
      if (this.$refs.form.validate()) {
        const data = {
          name: this.name,
          phone: this.phone,
          text: this.text
        };
        this.$http
          .post("/agents/user-profile/feedback", data)
          .then(() => {
            this.formSubmit = true;
          })
          .catch(err => {
            this.addErrors(err.response.data.data.errors);
            this.$refs.form.resetValidation();
          });
      }
    },
    addErrors: function(err) {
      err.forEach(item => {
        this.errors[item.field].push(item.message);
      });
    }
  }
};
</script>

<style scoped></style>
